import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ThemeService } from '../services/theme.service';
// import { DotCanvas } from 'src/assets/js/dots';
import { NavigationEnd, Router } from '@angular/router';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isDarkTheme!: Observable<boolean>;
  router: any;

  // private appContainer: HTMLElement | null = null;

  constructor(private themeService: ThemeService, router: Router) {
    this.router = router;
  }

  ngOnInit(): void {
    this.isDarkTheme = this.themeService.isDarkTheme;

    // this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    //     if (this.router.url === '/') {
    //       if (this.dotsCanvas.nativeElement) {
    //         this.initDots(); // Initialize the dots when navigating to the home route
    //       }
    //     }
    //   }
    // });
    gsap.registerPlugin(ScrollTrigger);

    const headings = document.querySelectorAll('.animate-text');

    headings.forEach((heading) => {
      gsap.from(heading, {
        scrollTrigger: {
          trigger: heading,
          start: 'top bottom',
          end: 'top top',
          toggleActions: 'restart pause resume pause',
          scroller: '.app-container',
          // markers: true,
        },
        opacity: 0,
        y: 30,
        duration: 1.2,
        ease: 'power2.inOut',
        stagger: 0.2, // Stagger each heading's animation by 0.2 seconds
      });
    });
  }

  // initDots() {
  //   const canvas = this.dotsCanvas?.nativeElement;

  //   // Ensure the canvas is available
  //   if (canvas) {
  //     const dotCanvas = new DotCanvas(canvas); // Pass the canvas element directly
  //     dotCanvas.startAnimation();
  //   } else {
  //     console.error('Canvas element not found');
  //   }
  // }

  ngAfterViewInit() {
    // this.appContainer = document.querySelector('.app-container');
    // this.appContainer?.addEventListener('scroll', this.onScroll.bind(this));
    // this.stickyWave();
  }

  // onScroll() {
  //   if (window.requestAnimationFrame) {
  //     window.requestAnimationFrame(() => this.stickyWave());
  //   } else {
  //     setTimeout(() => this.stickyWave(), 100);
  //   }
  // }

  // stickyWave(): void {
  //   const designSectionWave: any = document.getElementById('designSectionWave');
  //   const softwareDesign: any = document.querySelector('.software-design');
  //   const containerRect = this.appContainer!.getBoundingClientRect();
  //   const isInViewScrollDown =
  //     softwareDesign.getBoundingClientRect().top <= containerRect.bottom &&
  //     softwareDesign.getBoundingClientRect().bottom >= containerRect.top;
  //   console.log('in view?', isInViewScrollDown);
  //   console.log(
  //     designSectionWave.getBoundingClientRect().top,
  //     containerRect.bottom
  //   );
  //   if (isInViewScrollDown) {
  //     console.log('in view');
  //     designSectionWave.classList.add('position-fixed');
  //     designSectionWave.classList.remove('position-absolute');
  //   } else {
  //     designSectionWave.classList.remove('position-fixed');
  //     designSectionWave.classList.add('position-absolute');
  //   }
  // }
}
