import { Component, OnInit } from '@angular/core';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'phone3d',
  templateUrl: './phone3d.component.html',
  styleUrls: ['./phone3d.component.scss'],
})
export class Phone3dComponent implements OnInit {
  constructor() {}
  private gltf: any; // Declare the GLTF variable here
  private mesh: any;
  private scale: any = 0.7;

  ngOnInit(): void {
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    //MP4
    var material = new THREE.MeshStandardMaterial();
    let video: any = document.getElementById('task-manager-vid');
    // Wait for the video to be loaded
    video.addEventListener('loadeddata', () => {
      // Try to play the video
      video.play().catch((error: any) => {
        console.log('Video autoplay failed: ', error);
      });
    });
    material.map = new THREE.VideoTexture(video);

    let w = 0.425;
    let h = 0.78;
    var geometry = new THREE.PlaneGeometry(w, h);
    geometry.translate(0.221, 0.485, 0.028);
    // 0.221, 0.485, 0.028);
    material.roughness = 0.0;
    var mesh = new THREE.Mesh(geometry, material);
    // mesh.scale.set(1.05, 0.95, 0);
    mesh.rotation.set(0, 1, 0);
    // mesh.position.set(aspectRatio * 0.5, 0, 0);
    mesh.scale.set(1.4, 1.4, 1.4);
    mesh.position.set(0, -4.5, 0);
    this.mesh = mesh;

    const gltfLoader = new GLTFLoader();

    // Canvas
    const canvas = document.querySelector('canvas.phone-container');

    // Scene
    const scene = new THREE.Scene();
    scene.add(mesh);

    //PHONE
    gltfLoader.load('assets/3D_assets/phone/scene.gltf', (gltf) => {
      gltf.scene.scale.set(this.scale, this.scale, this.scale);
      gltf.scene.rotation.set(0, 1, 0);
      // gltf.scene.position.set(
      //   window.innerWidth * 0.5,
      //   window.innerHeight * 0.5,
      //   0
      // );
      gltf.scene.position.set(0, -4.5, 0);
      scene.add(gltf.scene);

      gltf.scene.traverse((child) => {
        if ((child as THREE.Mesh).material) {
          let material: any = (child as THREE.Mesh).material;
          material.metalness = 0.5;
        }
        this.gltf = gltf;
      });

      gsap.registerPlugin(ScrollTrigger);
      gsap.to(gltf.scene.rotation, {
        scrollTrigger: {
          trigger: '.phone',
          start: 'top top+=800px',
          end: 'bottom bottom-=400px',
          scrub: 1,
          toggleActions: 'restart pause resume pause',
          scroller: '.app-container',
        },
        y: 6.5,
      });
      gsap.to(gltf.scene.position, {
        scrollTrigger: {
          trigger: '.phone',
          start: 'top top+=800px',
          end: 'bottom bottom-=400px',
          scrub: 1,
          toggleActions: 'restart pause resume pause',
          scroller: '.app-container',
        },
        y: 1,
      });
      gsap.to(mesh.rotation, {
        scrollTrigger: {
          trigger: '.phone',
          start: 'top top+=800px',
          end: 'bottom bottom-=400px',
          scrub: 1,
          toggleActions: 'restart pause resume pause',
          scroller: '.app-container',
        },
        y: 6.5,
      });

      gsap.to(mesh.position, {
        scrollTrigger: {
          trigger: '.phone',
          start: 'top top+=800px',
          end: 'bottom bottom-=400px',
          scrub: 1,
          toggleActions: 'restart pause resume pause',
          scroller: '.app-container',
        },
        y: 1,
      });
    });

    // Lights

    const pointLight = new THREE.SpotLight(0xffffff, 1.3);
    pointLight.position.x = 1;
    pointLight.position.y = 2;
    pointLight.position.z = 5.8;
    scene.add(pointLight);

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas!,
      alpha: true,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    window.addEventListener('resize', () => {
      // Update sizes
      // sizes.width = window.innerWidth;
      // sizes.height = window.innerHeight;

      // Update camera
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      // Update renderer
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });

    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      0.1,
      100
    );
    camera.position.x = 0;
    camera.position.y = 1;
    camera.position.z = 2;
    scene.add(camera);

    // Controls
    // const controls = new OrbitControls(camera, canvas)
    // controls.enableDamping = true

    /**
     * Animate
     */

    const clock = new THREE.Clock();

    const animate = () => {
      const elapsedTime = clock.getElapsedTime();

      // Update objects
      // sphere.rotation.y = .5 * elapsedTime

      // Update Orbital Controls
      // controls.update()

      // Call tick again on the next frame
      window.requestAnimationFrame(animate);
      // Render
      renderer.render(scene, camera);
    };

    animate();
  }
}
