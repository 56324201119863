<div class="landing-container">
                
    <div class="landing-content">
      <h1 class="action-text animate-text">
        NICOLAS DE LUCA<br>
        FULL STACK DEVELOPER
      </h1>
      
      <p class="text-center mt-1 animate-text" style="font-size: 16px; max-width: 500px">building scalable applications and optimizing workflows across diverse technologies.</p>
      <br>
      <div style="text-align: center;">
        <connect-button></connect-button>
        <view-my-work-button :sectionName="projects2"></view-my-work-button>
      </div>

    </div>
    <!-- <div class="landing-content">
      <div class="giant-text">
        <h1>Hello!</h1>
        <h2>My name is</h2>
        <h3>nick de Luca</h3>
      </div>

      <mat-card class="card">
        <mat-card-actions align="end">
          <button mat-raised-button
                  color="primary"
                  class="cta">Lets Connect</button>
        </mat-card-actions>
        <mat-divider [vertical]="true"
                    style="height:450px; float:left; margin: 40px 0px 0px 10px"></mat-divider>

        <app-scroll-svg></app-scroll-svg>

      </mat-card>
    </div> -->



    <div class="dots-container">
      <canvas #dotsCanvas class="canvas"></canvas>
    </div>
    <div class="dots"></div>
  </div>