<div #contactFormContainer class="contact-form-container">
    <h2 class="contact-header">Get in Touch</h2>
    <form ngNoForm class="contact-form" #contactForm action="https://formspree.io/f/myzyedqp" method="POST">
      <mat-form-field class="form-field">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Your Name" name="name" required />
      </mat-form-field>
  
      <mat-form-field class="form-field">
        <mat-label>Email</mat-label>
        <input matInput type="email" placeholder="Your Email" name="email" required />
      </mat-form-field>

      <input type="hidden" name="_subject" value="Incoming Portfolio Message" />
  
      <mat-form-field class="form-field">
        <mat-label>Message</mat-label>
        <textarea matInput placeholder="Your Message" name="message" required style="min-height: 120px;"></textarea>
      </mat-form-field>
  
      <glow-button style="padding: 10px;" [expand]="true" (click)="onSubmit()" :url='' :actionText="Reach Out"></glow-button>

      <!-- <button mat-raised-button color="primary" class="submit-btn" type="submit">Send Message</button> -->
    </form>
  </div>
  