import { Component, OnInit } from '@angular/core';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'laptop3d',
  templateUrl: './laptop3d.component.html',
  styleUrls: ['./laptop3d.component.scss'],
})
export class Laptop3dComponent implements OnInit {
  constructor() {}
  private gltf: any; // Declare the GLTF variable here
  private scale: any = 0.7;
  private laptopLid: any; // To store the laptop lid mesh for hinge animation
  private laptop: any;
  private camera: any;
  private light: any;
  rotationValue = window.innerWidth > 650 ? 300 : 300; // This will hold the slider value for rotation
  positionXValue = 0;
  positionYValue = window.innerWidth > 650 ? 0.5 : 0.58;
  positionZValue = window.innerWidth > 650 ? 0.5 : 0.44;
  rotationlapXValue = 240;
  laptopYPos = -0.04;
  lightX = window.innerWidth > 650 ? 0 : 0.02;
  lightY = window.innerWidth > 650 ? 0.63 : 0.22;
  lightZ = window.innerWidth > 650 ? -0.66 : -0.27;
  lightintensity = 1;
  lightdistance = -1;
  isLidOpen: boolean = true; // Initialize the lid state
  private isUserClosingLid: boolean = false; // Track manual lid closure
  private screenMesh: any;
  private videoPaths = [
    'assets/mp4s/ecomapi.mp4',
    'assets/mp4s/delunico-drive.mp4',
    'assets/mp4s/Ollie.mp4',
    'assets/mp4s/task-manager.mp4',
    'assets/mp4s/sorting-algorithms.mp4',
  ];
  private currentVideoIndex = 0;

  loadVideo(path: string) {
    const video = document.createElement('video');
    video.src = path; // Your video file path
    video.loop = true; // Set the video to loop if desired
    video.muted = true; // Mute the video if needed

    video.oncanplay = () => video.play();

    // Create a texture from the video
    const videoTexture = new THREE.VideoTexture(video);
    videoTexture.minFilter = THREE.LinearFilter;
    videoTexture.magFilter = THREE.LinearFilter;
    videoTexture.format = THREE.RGBAFormat;

    this.screenMesh.material = new THREE.MeshBasicMaterial({
      map: videoTexture,
    });
    this.screenMesh.material.needsUpdate = true;
  }
  nextVideo() {
    this.currentVideoIndex =
      this.currentVideoIndex == this.videoPaths.length - 1
        ? 0
        : this.currentVideoIndex + 1;

    this.loadVideo(this.videoPaths[this.currentVideoIndex]);
  }
  ngOnInit(): void {
    const offset = 900;
    let sizes = {
      width:
        window.innerWidth > 650 ? window.innerWidth - 17 : window.innerWidth,
      height: window.innerHeight + offset,
    };

    const gltfLoader = new GLTFLoader();

    // Canvas
    const canvas = document.querySelector('canvas.laptop-canvas');

    // Scene
    const scene = new THREE.Scene();

    // Load the laptop model
    gltfLoader.load('assets/3D_assets/laptop/scene.gltf', (gltf) => {
      this.laptop = gltf.scene;
      const laptop = gltf.scene;
      laptop.scale.set(this.scale, this.scale, this.scale);
      laptop.position.set(0, this.laptopYPos, 0); // Adjust position as needed
      laptop.rotation.set(
        0,
        THREE.MathUtils.degToRad(this.rotationlapXValue),
        0
      );
      scene.add(laptop);

      // Traverse the model to find the lid (assuming it's named 'Laptop_Lid' or similar)
      laptop.traverse((child) => {
        if (child.name === 'Bevels_2') {
          this.laptopLid = child; // Save reference to the laptop lid
        }
      });

      // Initialize ScrollTrigger animations
      this.initScrollAnimations(laptop);

      // Find the mesh in your scene
      this.screenMesh = scene.getObjectByName('Object_7'); // Replace with your mesh name
      this.loadVideo(this.videoPaths[this.currentVideoIndex]);
    });

    // Lights
    const pointLight = new THREE.PointLight(
      0x8f8f8f,
      this.lightintensity,
      this.lightdistance
    );
    pointLight.position.set(this.lightX, this.lightY, this.lightZ);
    this.light = pointLight;
    scene.add(pointLight);

    // Renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas!,
      alpha: true,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    window.addEventListener('resize', () => {
      sizes = {
        width:
          window.innerWidth > 650 ? window.innerWidth - 17 : window.innerWidth,
        height: window.innerHeight + offset,
      }; // Offset you originally set

      // Update sizes for smaller screens or dynamically as required
      this.camera.aspect = sizes.width / sizes.height;
      this.camera.updateProjectionMatrix();

      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });

    // Camera
    const camera = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      0.1,
      100
    );
    camera.position.set(
      this.positionXValue,
      this.positionYValue,
      this.positionZValue
    ); // Adjust camera position as needed
    camera.rotation.set(THREE.MathUtils.degToRad(this.rotationValue), 0, 0);
    this.camera = camera;
    scene.add(camera);

    // Animate function
    const clock = new THREE.Clock();
    const animate = () => {
      window.requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };

    animate();
  }

  toggleLid() {
    if (this.laptopLid) {
      const targetRotation = this.isLidOpen ? 0 : -Math.PI / 2; // Close lid if open, open lid if closed
      this.isLidOpen = !this.isLidOpen; // Toggle the lid state
      this.isUserClosingLid = !this.isLidOpen; // Track if the user has closed the lid
      gsap.to(this.laptopLid.rotation, {
        x: targetRotation, // Rotate to the target angle
        duration: 1, // Duration of the animation
        overwrite: this.isUserClosingLid ? true : undefined,
      });
      if (!this.isUserClosingLid) {
        if (this.laptopLid) {
          gsap.to(this.laptopLid.rotation, {
            scrollTrigger: {
              trigger: '.projects2',
              start:
                window.innerWidth > 650 ? 'top-=900px top' : 'top-=50% top', // Start lid opening as laptop comes into view
              end: 'bottom-=25% bottom', // Complete lid opening when laptop is fully in view
              scrub: 1.5,
              toggleActions: 'restart pause resume pause',
              scroller: '.app-container',
              // markers: true,
            },
            x: !this.isUserClosingLid ? -Math.PI / 2 : 0, // Open the lid by rotating along the X-axis
            duration: 1, // Smooth opening of the lid
            onUpdate: () => {
              this.isLidOpen = true; // Update the state to indicate the lid is now open
              this.isUserClosingLid = false; // Reset the manual closing state
            },
          });
        }
      }
    }
  }

  // Initialize scroll-based animations using GSAP and ScrollTrigger
  private initScrollAnimations(laptop: THREE.Object3D) {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(this.camera.rotation, {
      scrollTrigger: {
        trigger: '.projects2', // The container for scroll trigger
        start: window.innerWidth > 650 ? 'top-=1000px top' : 'top-=65% top', // Adjust the start and end points
        end: 'bottom+=50% bottom',
        scrub: true, // Smooth scroll animation
        toggleActions: 'restart pause resume pause',
        scroller: '.app-container', // Make sure this is the scrollable container
        // markers: true,
      },
      x: THREE.MathUtils.degToRad(338), // Full rotation around the Y-axis
      duration: 1, // Duration adjusted to the scroll speed
      // ease: 'power.inOut',
    });

    gsap.to(laptop.rotation, {
      scrollTrigger: {
        trigger: '.projects2', // The container for scroll trigger
        start: window.innerWidth > 650 ? 'top-=1000px top' : 'top-=65% top', // Adjust the start and end points
        end: 'bottom-=25% bottom',
        scrub: 1.5, // Smooth scroll animation
        toggleActions: 'restart pause resume pause',
        scroller: '.app-container', // Make sure this is the scrollable container
        // markers: true,
      },
      y: THREE.MathUtils.degToRad(360), // Full rotation around the Y-axis
      duration: 1, // Duration adjusted to the scroll speed
      ease: 'power1.inOut',
    });

    // Adjust the camera's Y position to keep the laptop in frame
    gsap.to(this.camera.position, {
      scrollTrigger: {
        trigger: '.projects2',
        start: window.innerWidth > 650 ? 'top-=1000px top' : 'top-=65% top', // Adjust timing to align with the laptop's entry
        end: 'bottom-=25% bottom',
        scrub: true,
        toggleActions: 'restart pause resume pause',
        scroller: '.app-container',
        // markers: true,
      },
      y: window.innerWidth > 650 ? 0.18 : 0.32, // Adjust the camera height (Y) for a smooth track
      duration: 1,
    });

    // Adjust the camera's Z position for zoom-in effect as the laptop rotates
    gsap.to(this.camera.position, {
      scrollTrigger: {
        trigger: '.projects2',
        start: window.innerWidth > 650 ? 'top-=700px top' : 'top-=25% top', // Adjust for zoom during the scroll
        end: 'bottom+=5% bottom',
        scrub: 1.5,
        toggleActions: 'restart pause resume pause',
        scroller: '.app-container',
        // markers: true,
      },
      z: window.innerWidth > 650 ? 0.26 : 0.48, // Zoom closer to the laptop
      duration: 1,
      ease: 'power1.inOut',
    });

    // Animate the laptop lid opening as the laptop rotates into view
    if (this.laptopLid) {
      gsap.to(this.laptopLid.rotation, {
        scrollTrigger: {
          trigger: '.projects2',
          start: window.innerWidth > 650 ? 'top-=900px top' : 'top-=50% top', // Start lid opening as laptop comes into view
          end: 'bottom-=25% bottom', // Complete lid opening when laptop is fully in view
          scrub: 1.5,
          toggleActions: 'restart pause resume pause',
          scroller: '.app-container',
          // markers: true,
        },
        x: !this.isUserClosingLid ? -Math.PI / 2 : 0, // Open the lid by rotating along the X-axis
        duration: 1, // Smooth opening of the lid
      });
    }
  }
  // Function to update rotation when slider changes
  onSliderChange(event: any): void {
    const rotationAngle = THREE.MathUtils.degToRad(event.target.value); // Convert degrees to radians
    if (this.camera) {
      this.camera.rotation.x = rotationAngle; // Rotate the laptop around the Y-axis
    }
  }
  onSliderRotationlapXValueChange(event: any): void {
    const rotationAngle = THREE.MathUtils.degToRad(event.target.value); // Convert degrees to radians
    if (this.laptop) {
      this.laptop.rotation.y = rotationAngle; // Rotate the laptop around the Y-axis
    }
  }
  onSliderXChange(event: any): void {
    const positionValue = event.target.value; // Get the value from the slider
    if (this.camera) {
      this.camera.position.x = positionValue; // Update the laptop's position on the X-axis
    }
  }

  onSliderYChange(event: any): void {
    const positionValue = event.target.value; // Get the value from the slider
    if (this.camera) {
      this.camera.position.y = positionValue; // Update the laptop's position on the Y-axis
    }
  }
  onSlideLaptopYChange(event: any): void {
    const positionValue = event.target.value; // Get the value from the slider
    if (this.laptop) {
      this.laptop.position.y = positionValue; // Update the laptop's position on the Y-axis
    }
  }

  onSliderZChange(event: any): void {
    const positionValue = event.target.value; // Get the value from the slider
    if (this.camera) {
      this.camera.position.z = positionValue; // Update the laptop's position on the Z-axis
    }
  }

  lightXChange(event: any): void {
    const positionValue = event.target.value; // Get the value from the slider
    if (this.light) {
      this.light.position.x = positionValue; // Update the laptop's position on the Z-axis
    }
  }
  lightYChange(event: any): void {
    const positionValue = event.target.value; // Get the value from the slider
    if (this.light) {
      this.light.position.y = positionValue; // Update the laptop's position on the Z-axis
    }
  }
  lightZChange(event: any): void {
    const positionValue = event.target.value; // Get the value from the slider
    if (this.light) {
      this.light.position.z = positionValue; // Update the laptop's position on the Z-axis
    }
  }
  lightintensityChange(event: any): void {
    const positionValue = event.target.value; // Get the value from the slider
    if (this.light) {
      this.light.intensity = positionValue; // Update the laptop's position on the Z-axis
    }
  }
  lightdistanceChange(event: any): void {
    const positionValue = event.target.value; // Get the value from the slider
    if (this.light) {
      this.light.distance = positionValue; // Update the laptop's position on the Z-axis
    }
  }
}
