<div class="container">
    <div class="row project-container" style="transform: scale(0.95);">
        <div class="corner-icon top-left"></div>
        <div class="corner-icon top-right"></div>
        <div class="corner-icon bottom-left"></div>
        <div class="corner-icon bottom-right"></div>
      <!-- Card 1 -->
      <div class="col-md-6 col-12 card-container">
        <div class="corner-icon top-right"></div>
        <div class="corner-icon bottom-left"></div>
        <div class="corner-icon bottom-right"></div>
        <mat-card class="custom-card">


          <mat-card-title>RESTFUL E-commerce API</mat-card-title>
          <mat-card-content>
            <p>A secure eCommerce REST API featuring JWT authentication and role-based access control (RBAC), with comprehensive OpenAPI (Swagger) documentation, API request validation using class-validator for type safety, and a custom logging filter for comprehensive API monitoring.</p>
            <div class="d-flex flex-wrap">
                <div class="dashed-box">
                    <mat-icon>cloud_upload</mat-icon>
                    Automated CI/CD Deployments
                  </div>
                  
                  <div class="dashed-box">
                    <mat-icon>security</mat-icon>
                    Secure REST API Design
                  </div>
                  
                  <div class="dashed-box">
                    <mat-icon>attach_money</mat-icon>
                    Payment Integration
                  </div>
                  
                  <div class="dashed-box">
                    <mat-icon>dns</mat-icon>
                    Docker Container Load Balancing
                  </div>
            </div>
            <div class="buttons">
                <glow-button style="padding: 10px;" :url='/projects/1' :actionText="See More"></glow-button>
                <a href="https://github.com/NickDeLu/backend-template/tree/ecommerce-template" target="_blank">
                  <view-my-work-button actionText="Code"></view-my-work-button>
                </a>
            </div>

            <div class="img-container">
                <img src="assets/gifs/ecommerce-api-2.gif">
            </div>
          </mat-card-content>
        </mat-card>
      </div>
  
      <!-- Card 2 -->
      <div class="col-md-6 col-12 card-container">
        <div class="corner-icon bottom-left"></div>
        <div class="corner-icon bottom-right"></div>
        <mat-card class="custom-card">

          <mat-card-title>Task Manager</mat-card-title>
          <mat-card-content>
            <p>A task management web application that utilizes full CRUD operations through a RESTful API, seamlessly connecting an Angular frontend with a Node.js Express backend and a MySQL database for efficient task management.</p>
            <div class="d-flex flex-wrap">
                <div class="dashed-box">
                    <mat-icon>task</mat-icon>
                    Intuitive Task Management
                  </div>
                  
                  <div class="dashed-box">
                    <mat-icon>api</mat-icon>
                    RESTful API Design
                  </div>
                  
                  <div class="dashed-box">
                    <mat-icon>design_services</mat-icon>
                    Angular Material UI
                  </div>
                  
                  <div class="dashed-box">
                    <mat-icon>phone_iphone</mat-icon>
                    Mobile Responsive Design
                  </div>
                  
            </div>
            <div class="buttons">
              <glow-button style="padding: 10px;" :url='/projects/3' :actionText="See More"></glow-button>
              <a href="https://github.com/NickDeLu/delunico-taskmanager" target="_blank">
                <view-my-work-button actionText="Code"></view-my-work-button>
              </a>
            </div>
            
            <div class="img-container">
                <img src="assets/gifs/task-manager.gif">
            </div>
          </mat-card-content>          
        </mat-card>
      </div>
  
      <!-- Repeat for Cards 3, 4, 5, 6 -->
  
      <!-- Card 3 -->
      <div class="col-md-6 col-12 card-container">
        <div class="corner-icon bottom-left"></div>
        <div class="corner-icon bottom-right"></div>
        <mat-card class="custom-card">


          <mat-card-title>Delunico Drive</mat-card-title>
          <mat-card-content>
            <p>A React.js web app that implements file storage through the Google Firebase API, featuring user auth for secure file management, allowing users to upload, download, and organize files in directories linked to their accounts.</p>
            <div class="d-flex flex-wrap">
              <div class="dashed-box">
                <mat-icon>cloud_upload</mat-icon>
                Secure File Storage
              </div>
          
              <div class="dashed-box">
                <mat-icon>api</mat-icon>
                Firebase API Integration
              </div>
          
              <div class="dashed-box">
                <mat-icon>verified_user</mat-icon>
                User Authentication
              </div>
          
              <div class="dashed-box">
                <mat-icon>folder</mat-icon>
                Directory Management
              </div>
            </div>
            <div class="buttons">
              <glow-button style="padding: 10px;" :url='/projects/2' :actionText="See More"></glow-button>
              <a href="https://github.com/NickDeLu/delunico-google-drive-clone" target="_blank">
                <view-my-work-button actionText="Code"></view-my-work-button>
              </a>
            </div>

            <div class="img-container">
                <img src="assets/gifs/delunico-drive-2.gif">
            </div>
          </mat-card-content>
        </mat-card>
      </div>
  
      <!-- Card 4 -->
      <div class="col-md-6 col-12 card-container">
        <div class="corner-icon bottom-left"></div>
        <div class="corner-icon bottom-right"></div>
        <mat-card class="custom-card">


          <mat-card-title>Quixercise</mat-card-title>
          <mat-card-content>
            <p>A user-friendly exercise app that enables users to quickly search for exercises by body part, target muscle, equipment, or name, view GIF demonstrations, and save exercises for easy access on any iOS device.</p>
            <div class="d-flex flex-wrap">
          
              <div class="dashed-box">
                <mat-icon>fitness_center</mat-icon>
                Exercise Demonstrations
              </div>

              <div class="dashed-box">
                <mat-icon>search</mat-icon>
                Quick Exercise Search
              </div>

              <div class="dashed-box">
                <mat-icon>verified_user</mat-icon>
                User Authentication
              </div>
          
              <div class="dashed-box">
                <mat-icon>cloud_done</mat-icon>
                Saved Exercise Access
              </div>

            </div>
            <div class="buttons">
              <glow-button style="padding: 10px;" :url='/projects/4' :actionText="See More"></glow-button>
              <a href="https://github.com/Quixercise" target="_blank">
                <view-my-work-button actionText="Code"></view-my-work-button>
              </a>
            </div>

            <div class="img-container">
                <img src="assets/gifs/quixercise.gif">
            </div>
          </mat-card-content>
          
        </mat-card>
      </div>
  
      <!-- Card 5 -->
      <div class="col-md-6 col-12 card-container">
        <div class="corner-icon bottom-left"></div>
        <div class="corner-icon bottom-right"></div>
        <mat-card class="custom-card">


          <mat-card-title>Sorting Algorithm Visualizer</mat-card-title>
          <mat-card-content>
            <p>A Pygame app that visualizes bubble sort and quick sort algorithms, providing an interactive experience that helps users understand sorting mechanisms through real-time visualization.</p>
            <div class="d-flex flex-wrap">
              <div class="dashed-box">
                <mat-icon>code</mat-icon>
                Powered by Python
              </div>

              <div class="dashed-box">
                <mat-icon>assessment</mat-icon>
                Sorting Algorithm Visualizer
              </div>

              <div class="dashed-box">
                <mat-icon>widgets</mat-icon>
                Interactive UI Controls
              </div>
              
              <div class="dashed-box">
                <mat-icon>view_compact</mat-icon>
                Adjustable Window Size
              </div>
            </div>
            <div class="buttons">
              <glow-button style="padding: 10px;" :url='/projects/5' :actionText="See More"></glow-button>
              <a href="https://github.com/NickDeLu/SortingAlgorithms.git" target="_blank">
                <view-my-work-button actionText="Code"></view-my-work-button>
              </a>
            </div>
            <div class="img-container">
                <img src="assets/gifs/sortingalgorithms.gif">
            </div>
          </mat-card-content>
          
        </mat-card>
      </div>
  
      <!-- Card 6 -->
      <div class="col-md-6 col-12 card-container">
        <div class="corner-icon bottom-left"></div>
        <div class="corner-icon bottom-right"></div>
        <mat-card class="custom-card">

          <mat-card-title>Ollie AI</mat-card-title>
          <mat-card-content>
            <p>An AI assistant designed to detect, translate, and summarize large amounts of text from images, leveraging Cohere's Generate API for advanced text processing capabilities.</p>
            <div class="d-flex flex-wrap">
                <div class="dashed-box">
                    <mat-icon>camera_alt</mat-icon>
                    Image-to-Text Translation
                </div>

                <div class="dashed-box">
                    <mat-icon>photo_filter</mat-icon>
                    Cohere AI Text Summarization
                </div>

                <div class="dashed-box">
                    <mat-icon>cloud</mat-icon>
                    Google Cloud Integration
                </div>
                
                <div class="dashed-box">
                    <mat-icon>translate</mat-icon>
                    Multilingual Support
                </div>

            </div>
            <div class="buttons">
              <glow-button style="padding: 10px;" :url='/projects/6' :actionText="See More"></glow-button>
              <a href="https://github.com/NickDeLu/ollie" target="_blank">
                <view-my-work-button actionText="Code"></view-my-work-button>
              </a>
            </div>
            <div class="img-container">
                <img src="assets/gifs/ollieai.PNG">
            </div>
          </mat-card-content>
          
        </mat-card>
      </div>
    </div>
  </div>
  