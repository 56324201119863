import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'view-my-work-button',
  templateUrl: './view-my-work-button.component.html',
  styleUrls: ['./view-my-work-button.component.scss'],
})
export class ViewMyWorkButtonComponent {
  @Input() actionText: string = 'View My Work';
  @Input() sectionName: string = '';
  @Input() reverseArrow: string = 'false';
}
