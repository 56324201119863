import { Component, Input } from '@angular/core';

@Component({
  selector: 'glow-button',
  templateUrl: './glow-button.component.html',
  styleUrls: ['./glow-button.component.scss'],
})
export class GlowButtonComponent {
  @Input() expand: boolean = false;
  @Input() actionText: string = 'Resume';
  @Input() disableUrl: boolean = false;
  @Input() url: string = 'resume';

  // 'https://firebasestorage.googleapis.com/v0/b/auth-dev-ff796.appspot.com/o/files%2FouGePWcAQZMqy6DjSGzlQyq2ZZq2%2FNicolas_DeLuca_resume.docx?alt=media&token=d6b97201-c085-4764-ae88-4360cfa20cdf';
}
