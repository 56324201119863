<div class="timeline-container">
    <div id="timeline">
  
      <div class="timeline-item right">
        <div class="timeline-icon">
          <!-- <i class="fa fa-book" style="left: 15px;" aria-hidden="true"></i> -->
          <img src="/assets/company-icons/stripe.png">
        </div>
        <span class="time-stamp">Dec 2024 - Present</span>
        <div class="timeline-content right">
          <h2 class="d-none d-md-block">Integration Reliability Engineer - TechOps</h2>
          <h2 class="d-block d-md-none">IRE - Technical Operations</h2>
          <div class="company-name">Stripe Payments Canada, Ltd.</div>
          <ul>
              <li>Starting Dec 2024</li>
              <br><br><br>
          </ul>
        </div>
      </div>

        <div class="timeline-item">
          <div class="timeline-icon">
            <!-- <i class="fa fa-file-code-o " style="left: 15px;" aria-hidden="true"></i> -->
             <img src="/assets/company-icons/bit.png">
          </div>
          <span class="time-stamp">April 2023 - April 2024</span>
          <div class="timeline-content">
            <h2>Senior Software Developer</h2>
            <div class="company-name">Big Instance Technologies</div>
            <ul>
                <li>As a Scrum Master, planned 15 sub-projects for an Agile team of six, leading to project approval and generating over $100,000 in revenue.</li>
                <li>Led the migration of monolithic APIs to a microservices architecture using Kubernetes, Docker, and GitLab CI/CD, resulting in 60% reduction of response times. </li>
                <li>Developed software utilizing Python, Angular, and Node.js to automate complex user management workflows for over 250 users.</li>
            </ul>
          </div>
        </div>
  
        <div class="timeline-item right">
          <div class="timeline-icon">
            <!-- <i class="fa fa-book" style="left: 15px;" aria-hidden="true"></i> -->
            <img src="/assets/company-icons/sheridan.webp">
          </div>
          <span class="time-stamp">Nov 2022 - Feb 2023</span>
          <div class="timeline-content right">
            <h2>Student Research Assistant</h2>
            <div class="company-name">Sheridan College</div>
            <ul>
                <li>Managed the refinement of 4 technical exams (web programming, database, IOS dev, Angular/Spring) written for Sheridan's SPLAR project to ensure the quality, accuracy, and fairness of course material.</li>
            </ul>
          </div>
        </div>
  
        <div class="timeline-item">
          <div class="timeline-icon">
            <!-- <i class="fa fa-cogs" style="left: 13px;" aria-hidden="true"></i> -->
            <img src="/assets/company-icons/blackberry.png">
          </div>
          <span class="time-stamp">Sep 2021 - Aug 2022</span>
          <div class="timeline-content" style="background: #ffffff17;">
            <h2>Automation Software Developer</h2>
            <div class="company-name">BlackBerry Ltd. | Co-op 8 months</div>
            <ul>
                <li>Developed and architected 4 full-stack internal web tools’ frontend, backend, database, and deployment to
automate change and process management workflows saving over ~$17,000 of employee labor/year.</li>

<li>Wrote and executed 200+ unit and end-to-end tests using Jest and Supertest with a Postgres mock in-
memory database to guarantee high-quality code for deployment to production instances.</li>

<li>Created configurable deployment templates using Terraform with chef.io recipes to deploy OpenStack
virtual machines for automating app deployments for other employees.</li>
            </ul>
          </div>
        </div>
  
         <div class="timeline-item right">
          <div class="timeline-icon">
            <!-- <i class="fa fa-users" style="left: 13px;" aria-hidden="true"></i> -->
            <img src="/assets/company-icons/google.png">
          </div>
          <span class="time-stamp">July 2021 - Feb 2023</span>
          <div class="timeline-content right">
            <h2>Workshops and Talks Co-Lead</h2>
            <div class="company-name">Google Developer Student Club</div>
            <ul>
                <li>Plan, execute and lead learning workshops about popular web and application development technologies.</li>
                <li>Led an introductory workshop for the Google Cloud campaign, engaging over 2,000 attendees from hundreds of schools.</li>
            </ul>
          </div>
        </div>
  
      <div class="timeline-item">
          <div class="timeline-icon">
            <!-- <i class="fa fa-laptop" style="left: 13px;" aria-hidden="true"></i> -->
            <img src="/assets/company-icons/wmp.png">
          </div>
          <span class="time-stamp">Feb 2021 - May 2021</span>
          <div class="timeline-content">
            <h2>Angular and Node.js Developer</h2>
            <div class="company-name">WorldMoneyPay | Co-op 4 months</div>
            <ul>
                <li>Led a team of 3 to implement over 60 wireframes into responsive web pages with functional components.</li>
                <li>Normalized user database table to achieve minimum data redundancy and refactored changes in Node APIs.</li>
            </ul>
          </div>
    </div>
  
         <!-- <div class="timeline-item right">
          <div class="timeline-icon">
            <i class="fa fa-key" aria-hidden="true"></i>
          </div>
          <div class="timeline-content right">
            <h2>Application Download Key</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, facilis quo. Maiores magnam modi ab libero praesentium blanditiis consequatur aspernatur accusantium maxime molestiae sunt ipsa.
            </p>
             <span class="time-stamp">Thu Jan - 26 - 2017 01:54</span>
          </div>
        </div>
  
          <div class="timeline-item">
          <div class="timeline-icon success">
            <i class="fa fa-star" aria-hidden="true"></i>
          </div>
          <div class="timeline-content">
            <h2>Quick Trial Generation Started</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, facilis quo. Maiores magnam modi ab libero praesentium blanditiis consequatur aspernatur accusantium maxime molestiae sunt ipsa.
            </p>
             <span class="time-stamp">Thu Jan - 26 - 2017 01:54</span>
          </div>
        </div> -->
  
    </div>
    </div>
  
  