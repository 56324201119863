// console.clear();

// // Set the fixed distance between dots
// const DOT_DISTANCE = 16; // Distance between dots in pixels

// // Retrieve colors from CSS variables
// const DEFAULT_COLOR = getComputedStyle(document.documentElement)
//   .getPropertyValue("--default-dot-color")
//   .trim(); // Default dot color
// console.log(DEFAULT_COLOR);
// const HOVER_COLOR = getComputedStyle(document.documentElement)
//   .getPropertyValue("--dots-colorized")
//   .trim(); // Hover color'
// console.log(HOVER_COLOR); // Hover

// const canvas = document.getElementById("dotsCanvas");
// console.log(canvas);
// const ctx = canvas.getContext("2d");

// const fps = 10;
// let fpsInterval = 1000 / fps;
// let then = window.performance.now();

// let dots = [];
// let mousePos = {
//   x: 0,
//   y: 0,
// };

// class Dot {
//   constructor(x, y, size = 4) {
//     this.pos = {
//       x: x,
//       y: y,
//     };
//     this.size = size; // Dot size
//     this.defaultColor = DEFAULT_COLOR; // Set default color for the dot
//   }

//   draw() {
//     // Calculate distance to the mouse
//     this.calculateDistance();

//     // Determine color based on distance to the mouse
//     const maxDistance = 200; // Maximum distance for colorization
//     let color;

//     if (this.distance < maxDistance) {
//       // Interpolate color based on distance
//       const alpha = this.distance / maxDistance; // 0 to 1 based on distance (reversed)
//       color = interpolateColor(HOVER_COLOR, this.defaultColor, alpha);
//       console.log("interpolated", color);
//     } else {
//       color = this.defaultColor; // Use default color if outside the hover area
//     }

//     // Draw the dot
//     ctx.fillStyle = color;
//     ctx.beginPath();
//     ctx.arc(this.pos.x, this.pos.y, this.size, 0, 2 * Math.PI, false);
//     ctx.fill();
//   }

//   calculateDistance() {
//     const dx = mousePos.x - this.pos.x;
//     const dy = mousePos.y - this.pos.y;
//     this.distance = Math.sqrt(dx * dx + dy * dy);
//   }
// }

// // Function to interpolate between two colors
// function interpolateColor(color1, color2, alpha) {
//   const [r1, g1, b1] = hexToRgb(color1);
//   const [r2, g2, b2] = hexToRgb(color2);

//   const r = Math.round(r1 + (r2 - r1) * alpha);
//   const g = Math.round(g1 + (g2 - g1) * alpha);
//   const b = Math.round(b1 + (b2 - b1) * alpha);

//   return rgbToHex(r, g, b);
// }

// // Helper function to convert hex to RGB
// function hexToRgb(hex) {
//   const bigint = parseInt(hex.slice(1), 16);
//   const r = (bigint >> 16) & 255;
//   const g = (bigint >> 8) & 255;
//   const b = bigint & 255;
//   return [r, g, b];
// }

// // Helper function to convert RGB to hex
// function rgbToHex(r, g, b) {
//   return `#${((1 << 24) + (r << 16) + (g << 8) + b)
//     .toString(16)
//     .slice(1)
//     .toUpperCase()}`;
// }

// function createGrid() {
//   const pointSize = DOT_DISTANCE; // Use fixed distance
//   for (let x = pointSize / 2; x < canvas.width; x += pointSize) {
//     for (let y = pointSize / 2; y < canvas.height; y += pointSize) {
//       dots.push(new Dot(x, y, 1)); // Set dot size here
//     }
//   }
// }

// function loop(newtime) {
//   requestAnimationFrame(loop);

//   let now = newtime;
//   let elapsed = now - then;

//   if (elapsed > fpsInterval) {
//     then = now - (elapsed % fpsInterval);
//     ctx.clearRect(0, 0, canvas.width, canvas.height);

//     // Draw each dot
//     dots.forEach((dot) => {
//       dot.draw();
//     });
//   }
// }

// function getMousePos(event, canvas) {
//   const rect = canvas.getBoundingClientRect();
//   const x = event.clientX - rect.left;
//   const y = event.clientY - rect.top;
//   mousePos.x = x;
//   mousePos.y = y;
// }

// window.addEventListener("mousemove", function (e) {
//   getMousePos(e, canvas);
// });

// window.addEventListener("resize", () => {
//   startAnimation();
// });

// function startAnimation() {
//   dots = [];
//   canvas.width = canvas.offsetWidth;
//   canvas.height = canvas.offsetHeight;

//   createGrid();
//   loop();
// }

// startAnimation();

// dots.js

export class DotCanvas {
  constructor(canvas) {
    this.canvas = canvas;
    this.ctx = this.canvas.getContext("2d");
    this.DOT_DISTANCE = 16; // Distance between dots in pixels
    this.fps = 10;
    this.fpsInterval = 1000 / this.fps;
    this.then = window.performance.now();
    this.dots = [];
    this.mousePos = { x: 0, y: 0 };
    this.animationFrameId = null;

    this.mouseMoveListener = (e) => this.getMousePos(e);
    this.resizeListener = () => this.startAnimation();

    window.addEventListener("mousemove", this.mouseMoveListener);
    window.addEventListener("resize", this.resizeListener);
    this.startAnimation();
  }

  startAnimation() {
    this.dots = [];
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;

    this.createGrid();
    this.then = window.performance.now();
    this.loop();
  }

  createGrid() {
    const pointSize = this.DOT_DISTANCE;
    for (let x = pointSize / 2; x < this.canvas.width; x += pointSize) {
      for (let y = pointSize / 2; y < this.canvas.height; y += pointSize) {
        this.dots.push(new Dot(x, y, 1)); // Set dot size here
      }
    }
  }

  loop(newtime) {
    this.animationFrameId = requestAnimationFrame((time) => this.loop(time));

    const now = newtime;
    const elapsed = now - this.then;

    if (elapsed > this.fpsInterval) {
      this.then = now - (elapsed % this.fpsInterval);
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.dots.forEach((dot) => dot.draw(this.ctx, this.mousePos));
    }
  }

  getMousePos(event) {
    const rect = this.canvas.getBoundingClientRect();
    this.mousePos.x = event.clientX - rect.left;
    this.mousePos.y = event.clientY - rect.top;
  }

  // Stop animation and remove event listeners
  cleanup() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
    window.removeEventListener("mousemove", this.mouseMoveListener);
    window.removeEventListener("resize", this.resizeListener);
  }
}

// Define the Dot class
class Dot {
  constructor(x, y, size = 4) {
    this.pos = { x: x, y: y };
    this.size = size;
  }

  draw(ctx, mousePos) {
    this.calculateDistance(mousePos);

    const DEFAULT_COLOR = getComputedStyle(document.documentElement)
      .getPropertyValue("--default-dot-color")
      .trim();
    const HOVER_COLOR = getComputedStyle(document.documentElement)
      .getPropertyValue("--dots-colorized")
      .trim();

    const maxDistance = 200;
    let color;

    if (this.distance < maxDistance) {
      const alpha = this.distance / maxDistance;
      color = interpolateColor(HOVER_COLOR, DEFAULT_COLOR, alpha);
    } else {
      color = DEFAULT_COLOR;
    }

    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(this.pos.x, this.pos.y, this.size, 0, 2 * Math.PI, false);
    ctx.fill();
  }

  calculateDistance(mousePos) {
    const dx = mousePos.x - this.pos.x;
    const dy = mousePos.y - this.pos.y;
    this.distance = Math.sqrt(dx * dx + dy * dy);
  }
}

// Function to interpolate between two colors
function interpolateColor(color1, color2, alpha) {
  const [r1, g1, b1] = hexToRgb(color1);
  const [r2, g2, b2] = hexToRgb(color2);

  const r = Math.round(r1 + (r2 - r1) * alpha);
  const g = Math.round(g1 + (g2 - g1) * alpha);
  const b = Math.round(b1 + (b2 - b1) * alpha);

  return rgbToHex(r, g, b);
}

// Helper function to convert hex to RGB
function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
}

// Helper function to convert RGB to hex
function rgbToHex(r, g, b) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
}
