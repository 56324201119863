export const alltools = [
  {
    name: 'NestJS',
    html: '<img src="https://img.shields.io/badge/NestJS-C60000.svg?style=for-the-badge&amp;logo=NestJS&amp;logoColor=white" alt="NestJS">',
  },
  {
    name: 'VueJS',
    html: '<img src="https://img.shields.io/badge/VueJS-339963.svg?style=for-the-badge&amp;logo=VueJS&amp;logoColor=white" alt="VueJS">',
  },
  {
    name: 'MySQL',
    html: '<img src="https://img.shields.io/badge/MySQL-4479A1.svg?style=for-the-badge&amp;logo=MySQL&amp;logoColor=white" alt="MySQL">',
  },
  {
    name: 'Rest',
    html: '<img src="https://img.shields.io/badge/Rest-008CBA.svg?style=for-the-badge&amp;logo=Rest&amp;logoColor=white" alt="Rest">',
  },
  {
    name: 'TypeScript',
    html: '<img src="https://img.shields.io/badge/TypeScript-007ACC.svg?style=for-the-badge&amp;logo=TypeScript&amp;logoColor=white" alt="TypeScript">',
  },
  {
    name: 'NodeJS',
    html: '<img src="https://img.shields.io/badge/NodeJS-339933.svg?style=for-the-badge&amp;logo=Node.js&amp;logoColor=white" alt="NodeJS">',
  },
  {
    name: 'Express',
    html: '<img src="https://img.shields.io/badge/Express-191970.svg?style=for-the-badge&amp;logo=Express&amp;logoColor=white" alt="Express">',
  },
  {
    name: 'OpenAPI Swagger',
    html: '<img src="https://img.shields.io/badge/OpenAPI%20Swagger-7B68EE.svg?style=for-the-badge&amp;logo=OpenAPI+Swagger&amp;logoColor=white" alt="OpenAPI Swagger">',
  },
  {
    name: 'Combodocs',
    html: '<img src="https://img.shields.io/badge/Combodocs-0077C9.svg?style=for-the-badge&amp;logo=Combodocs&amp;logoColor=white" alt="Combodocs">',
  },
  {
    name: 'Hetzner',
    html: '<img src="https://img.shields.io/badge/Hetzner-00A3E0.svg?style=for-the-badge&amp;logo=Hetzner&amp;logoColor=white" alt="Hetzner">',
  },
  {
    name: 'Docker',
    html: '<img src="https://img.shields.io/badge/Docker-2496ED.svg?style=for-the-badge&amp;logo=Docker&amp;logoColor=white" alt="Docker">',
  },
  {
    name: 'Coolify',
    html: '<img src="https://img.shields.io/badge/Coolify-5C4EE5.svg?style=for-the-badge&amp;logo=Coolify&amp;logoColor=white" alt="Coolify">',
  },
  {
    name: 'GitHub Actions',
    html: '<img src="https://img.shields.io/badge/GitHub%20Actions-2088FF.svg?style=for-the-badge&amp;logo=GitHub+Actions&amp;logoColor=white" alt="GitHub Actions">',
  },
  {
    name: 'Cloudflare',
    html: '<img src="https://img.shields.io/badge/Cloudflare-F7C23D.svg?style=for-the-badge&amp;logo=Cloudflare&amp;logoColor=white" alt="Cloudflare">',
  },
  {
    name: 'Traefik',
    html: '<img src="https://img.shields.io/badge/Traefik-00A3E0.svg?style=for-the-badge&amp;logo=Traefik&amp;logoColor=white" alt="Traefik">',
  },
  {
    name: 'PassportJS',
    html: '<img src="https://img.shields.io/badge/PassportJS-FB5A5A.svg?style=for-the-badge&amp;logo=PassportJS&amp;logoColor=white" alt="PassportJS">',
  },
  {
    name: 'React',
    html: '<img src="https://img.shields.io/badge/React-61DAFB.svg?style=for-the-badge&logo=React&logoColor=white" alt="React">',
  },
  {
    name: 'Bootstrap',
    html: '<img src="https://img.shields.io/badge/Bootstrap-7952B3.svg?style=for-the-badge&logo=Bootstrap&logoColor=white" alt="Bootstrap">',
  },
  {
    name: 'Firebase',
    html: '<img src="https://img.shields.io/badge/Firebase-FFCA28.svg?style=for-the-badge&logo=Firebase&logoColor=black" alt="Firebase">',
  },
  {
    name: 'HTML',
    html: '<img src="https://img.shields.io/badge/HTML-E34F26.svg?style=for-the-badge&logo=HTML5&logoColor=white" alt="HTML">',
  },
  {
    name: 'CSS',
    html: '<img src="https://img.shields.io/badge/CSS-1572B6.svg?style=for-the-badge&logo=CSS3&logoColor=white" alt="CSS">',
  },
  {
    name: 'Go',
    html: '<img src="https://img.shields.io/badge/Go-00ADD8.svg?style=for-the-badge&logo=Go&logoColor=white" alt="Go">',
  },
  {
    name: 'Stripe',
    html: '<img src="https://img.shields.io/badge/Stripe-00AFE1.svg?style=for-the-badge&amp;logo=Stripe&amp;logoColor=white" alt="Stripe">',
  },
  {
    name: 'Vite',
    html: '<img src="https://img.shields.io/badge/Vite-42B883.svg?style=for-the-badge&amp;logo=Vite&amp;logoColor=white" alt="Vite">',
  },
  {
    name: 'Google Cloud Platform',
    html: '<img src="https://img.shields.io/badge/Google%20Cloud%20Platform-4285F4.svg?style=for-the-badge&amp;logo=Google%20Cloud%20Platform&amp;logoColor=white" alt="Google Cloud Platform">',
  },
  {
    name: 'Translate API',
    html: '<img src="https://img.shields.io/badge/Translate%20API-F4B400.svg?style=for-the-badge&amp;logo=Google%20Translate&amp;logoColor=white" alt="Translate API">',
  },
  {
    name: 'Vision API',
    html: '<img src="https://img.shields.io/badge/Vision%20API-4285F4.svg?style=for-the-badge&amp;logo=Google%20Cloud%20Vision&amp;logoColor=white" alt="Vision API">',
  },
  {
    name: 'Netlify',
    html: '<img src="https://img.shields.io/badge/Netlify-00C7B7.svg?style=for-the-badge&amp;logo=Netlify&amp;logoColor=white" alt="Netlify">',
  },
  {
    name: 'Cohere API',
    html: '<img src="https://img.shields.io/badge/Cohere%20API-0B7C8E.svg?style=for-the-badge&amp;logo=Cohere+API&amp;logoColor=white" alt="Cohere API">',
  },
  {
    name: 'Swift UI',
    html: '<img src="https://img.shields.io/badge/Swift%20UI-5A9EEC.svg?style=for-the-badge&amp;logo=Swift&amp;logoColor=white" alt="Swift UI">',
  },
  {
    name: 'Zeplin.io',
    html: '<img src="https://img.shields.io/badge/Zeplin.io-FB9B00.svg?style=for-the-badge&amp;logo=Zeplin&amp;logoColor=white" alt="Zeplin.io">',
  },
  {
    name: 'XCode',
    html: '<img src="https://img.shields.io/badge/XCode-2BA0E8.svg?style=for-the-badge&amp;logo=XCode&amp;logoColor=white" alt="XCode">',
  },
  {
    name: 'Adobe XD',
    html: '<img src="https://img.shields.io/badge/Adobe%20XD-8B008B.svg?style=for-the-badge&amp;logo=Adobe+XD&amp;logoColor=white" alt="Adobe XD">',
  },
  {
    name: 'RapidAPI',
    html: '<img src="https://img.shields.io/badge/RapidAPI-0A2E83.svg?style=for-the-badge&amp;logo=RapidAPI&amp;logoColor=white" alt="RapidAPI">',
  },
  {
    name: 'ExerciseDB',
    html: '<img src="https://img.shields.io/badge/ExerciseDB-7C3BAD.svg?style=for-the-badge&amp;logo=ExerciseDB&amp;logoColor=white" alt="ExerciseDB">',
  },
  {
    name: 'Express API',
    html: '<img src="https://img.shields.io/badge/Express%20API-9C9C9C.svg?style=for-the-badge&amp;logo=Express&amp;logoColor=white" alt="Express API">',
  },
  {
    name: 'Python',
    html: '<img src="https://img.shields.io/badge/Python-306998.svg?style=for-the-badge&amp;logo=Python&amp;logoColor=white" alt="Python">',
  },
  {
    name: 'Pygame',
    html: '<img src="https://img.shields.io/badge/Pygame-AA3939.svg?style=for-the-badge&amp;logo=Pygame&amp;logoColor=white" alt="Pygame">',
  },
  {
    name: 'Quick Sort',
    html: '<img src="https://img.shields.io/badge/Quick_Sort-2ECC71.svg?style=for-the-badge" alt="Quick Sort">',
  },
  {
    name: 'Bubble Sort',
    html: '<img src="https://img.shields.io/badge/Bubble_Sort-FF5733.svg?style=for-the-badge" alt="Bubble Sort">',
  },
  {
    name: 'Atom',
    html: '<img src="https://img.shields.io/badge/Atom-2DAE86.svg?style=for-the-badge&amp;logo=Atom&amp;logoColor=white" alt="Atom">',
  },
  {
    name: 'Angular',
    html: '<img src="https://img.shields.io/badge/Angular-DD0031.svg?style=for-the-badge&amp;logo=Angular&amp;logoColor=white" alt="Angular">',
  },
  {
    name: 'MaterialUI',
    html: '<img src="https://img.shields.io/badge/Material_UI-0081CB.svg?style=for-the-badge&amp;logo=Material-UI&amp;logoColor=white" alt="MaterialUI">',
  },
  {
    name: 'Ethereum',
    html: '<img src="https://img.shields.io/badge/Ethereum-30C5FD.svg?style=for-the-badge&amp;logo=Ethereum&amp;logoColor=white" alt="Ethereum">',
  },
  {
    name: 'BigchainDB',
    html: '<img src="https://img.shields.io/badge/BigchainDB-1D1D1D.svg?style=for-the-badge&amp;logo=BigchainDB&amp;logoColor=white" alt="BigchainDB">',
  },
  {
    name: 'Tendermint',
    html: '<img src="https://img.shields.io/badge/Tendermint-1797AB.svg?style=for-the-badge&amp;logo=Tendermint&amp;logoColor=white" alt="Tendermint">',
  },
  {
    name: 'MongoDB',
    html: '<img src="https://img.shields.io/badge/MongoDB-47A248.svg?style=for-the-badge&amp;logo=MongoDB&amp;logoColor=white" alt="MongoDB">',
  },
  {
    name: 'Chainlink',
    html: '<img src="https://img.shields.io/badge/Chainlink-3C9FAB.svg?style=for-the-badge&amp;logo=Chainlink&amp;logoColor=white" alt="Chainlink">',
  },
  {
    name: 'Mapbox API',
    html: '<img src="https://img.shields.io/badge/Mapbox%20API-30BCF2.svg?style=for-the-badge&amp;logo=Mapbox+API&amp;logoColor=white" alt="Mapbox API">',
  },
  {
    name: 'Geoapify',
    html: '<img src="https://img.shields.io/badge/Geoapify-7F7F7F.svg?style=for-the-badge&amp;logo=Geoapify&amp;logoColor=white" alt="Geoapify">',
  },
  {
    name: 'Solidity',
    html: ' <img src="https://img.shields.io/badge/Solidity-F6851F.svg?style=for-the-badge&amp;logo=Solidity&amp;logoColor=white" alt="Solidity">',
  },
];
