import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

// declare var particlesJS: any;
@Injectable()
export class ThemeService {
  private _darkTheme = new Subject<boolean>();
  isDarkTheme = this._darkTheme.asObservable();

  setDarkTheme(isDarkTheme: boolean): void {
    this._darkTheme.next(isDarkTheme);
    document.documentElement.style.setProperty(
      '--default-dot-color',
      isDarkTheme ? '#212121' : '#d1d1d1'
    );
    document.documentElement.style.setProperty(
      '--dots-colorized',
      isDarkTheme ? '#0896ff' : '#1b5df7'
    );

    document.documentElement.style.setProperty(
      '--svg-text',
      !isDarkTheme ? 'white' : 'black'
    );
  }
}
