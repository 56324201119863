import { Component } from '@angular/core';

@Component({
  selector: 'app-resume-page',
  templateUrl: './resume-page.component.html',
  styleUrls: ['./resume-page.component.scss'],
})
export class ResumePageComponent {
  private appContainer: HTMLElement | null = null;

  ngAfterViewInit() {
    this.appContainer = document.querySelector('.app-container');
    if (this.appContainer) {
      this.appContainer.scrollTop = 0;
    }
  }
}
