import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ThemeService } from '../../../services/theme.service';

@Component({
  selector: 'app-scroll-svg',
  templateUrl: './scroll-svg.component.html',
  styleUrls: ['./scroll-svg.component.scss'],
})
export class ScrollSvgComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
