
<div id="ci-cd-timeline" class="timeline d-flex">
    <div class="cicd-item" *ngFor="let item of stages" [id]="item.id">
        <div class="timeline-icon is-hidden" [ngClass]="{ 'animate-it': progressValue > item.progress, 'is-hidden': progressValue < item.progress }"></div>
        <div class="timeline-content is-hidden"  
        [ngClass]="{ 'animate-it': progressValue > item.progress,
                'is-hidden': progressValue < item.progress
            }">
            <ng-container *ngIf="item.icon; else matIconTemplate">
                <i [class]="item.icon"  style="font-size: 60px; width: 60px;" aria-hidden="true"></i>
            </ng-container>
            
            <!-- Render the <mat-icon> if item.matIcon is not null -->
            <ng-template #matIconTemplate>
                <mat-icon style="font-size: 60px; width: 60px;" *ngIf="item.mat_icon">{{ item.mat_icon }}</mat-icon>
            </ng-template>

            <h3>{{ item.title }}</h3>
        </div>
    </div>
</div>

  