import { Component, OnInit } from '@angular/core';

declare var KUTE: any;

@Component({
  selector: 'app-blobs-svg',
  templateUrl: './blobs-svg.component.html',
  styleUrls: ['./blobs-svg.component.scss'],
})
export class BlobsSvgComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    console.log(KUTE);
    window.addEventListener('DOMContentLoaded', (e) => {
      let blob = document.getElementById('blob99');
      blob!.addEventListener('click', function (e: any) {
        console.log('event', e.layerY);
        let scrollSection = '';
        if (e.layerY < 450) {
          scrollSection = '.projects';
        } else if (e.layerY < 1200) {
          scrollSection = '.projects2';
        } else if (e.layerY < 2500) {
          scrollSection = '.experience';
        } else if (e.layerY < 4100) {
          scrollSection = '.about';
        } else if (e.layerY < 5200) {
          scrollSection = '.contact';
        } else {
          scrollSection = '.landing';
        }

        document.querySelector(scrollSection)!.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
          block: 'center',
        });
      });
    });
  }
}
