import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import gsap from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
import * as confetti from 'canvas-confetti';

@Component({
  selector: 'app-contact',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  @ViewChild('contactForm') contactForm: any;

  contact = {
    name: '',
    email: '',
    message: '',
  };

  @ViewChild('contactFormContainer', { static: true })
  contactFormContainer!: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // GSAP animation for form entrance
    gsap.from(this.contactFormContainer.nativeElement, {
      opacity: 0,
      y: 20,
      duration: 1,
      ease: 'power3.out',
    });
  }
  onSubmit(): void {
    (confetti as any).default({
      disableForReducedMotion: true,
    });

    gsap.to(this.contactFormContainer.nativeElement, {
      scale: 1.05,
      duration: 0.1,
      yoyo: true,
      ease: 'power3.out',
    });
    // Submit the form programmatically
    this.contactForm.nativeElement.submit();

    this.contactForm.nativeElement.reset();
    alert("Thank you for reaching out! I'll get back to you soon.");
  }

  @HostListener('focusin', ['$event.target'])
  onFocusIn(input: HTMLInputElement): void {
    gsap.to(input, {
      boxShadow: '0px 4px 6px rgba(0, 120, 212, 0.4)', // Adjust color for theme
      duration: 0.3,
      ease: 'power3.out',
    });
  }

  @HostListener('focusout', ['$event.target'])
  onFocusOut(input: HTMLInputElement): void {
    gsap.to(input, {
      boxShadow: 'none',
      duration: 0.3,
      ease: 'power3.out',
    });
  }

  @HostListener('mouseenter', ['$event.target'])
  onMouseEnter(button: HTMLElement): void {
    gsap.to(button, {
      scale: 1.05,
      duration: 0.3,
      ease: 'power3.out',
    });
  }

  @HostListener('mouseleave', ['$event.target'])
  onMouseLeave(button: HTMLElement): void {
    gsap.to(button, {
      scale: 1,
      duration: 0.3,
      ease: 'power3.out',
    });
  }
}
