import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { alltools } from '../tools';
import { projects } from './projects';
import { ThemeService } from 'src/services/theme.service';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.scss'],
})
export class ProjectPageComponent {
  public isDarkTheme!: Observable<boolean>;
  projectId: string | null = null;
  project: any = null;
  projectTools: any = [];
  private appContainer: HTMLElement | null = null;
  private subscriptions = new Subscription();
  router: any;

  constructor(
    private route: ActivatedRoute,
    private themeService: ThemeService,
    router: Router
  ) {
    this.router = router;
  }

  ngOnInit(): void {
    this.isDarkTheme = this.themeService.isDarkTheme;

    const urlSegments = this.router.url.split('/');
    this.projectId = urlSegments[urlSegments.length - 1];
    console.log('proejctId', this.projectId);

    this.project = projects.find(
      (p: any) => p.id == this.projectId || p.legacy_key == this.projectId
    );
    this.projectTools = this.project.tools.map((t: any) =>
      alltools.find((at) => at.name == t)
    );
  }

  ngAfterViewInit() {
    this.appContainer = document.querySelector('.app-container');
    if (this.appContainer) {
      this.appContainer.scrollTop = 0;
    }
  }
}
