import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectPageComponent } from './components/project-page/project-page.component';
import { ResumePageComponent } from './components/resume-page/resume-page.component';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: 'projects/:id',
    component: ProjectPageComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: '',
    component: AppComponent,
    runGuardsAndResolvers: 'always',
  },
  { path: 'resume', component: ResumePageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
