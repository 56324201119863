import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DotCanvas } from 'src/assets/js/dots';

@Component({
  selector: 'landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  @ViewChild('dotsCanvas', { static: false })
  dotsCanvas!: ElementRef<HTMLCanvasElement>;
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initDots();
  }

  initDots() {
    const canvas = this.dotsCanvas?.nativeElement;

    // Ensure the canvas is available
    if (canvas) {
      const dotCanvas = new DotCanvas(canvas); // Pass the canvas element directly
      dotCanvas.startAnimation();
    } else {
      console.error('Canvas element not found');
    }
  }
}
