<nav class="topnav" id="myTopnav">
    <div class="nav-container">
        <div class="navlinks d-flex">
            <img id="logo" class ="logo" [attr.src]="(isDarkTheme | async) ? 'assets/img/Logo.PNG' : 'assets/img/Logo-light.PNG'" (click)="toTop()">
            <div class='navlink-container' *ngIf="goBackPage != 'true'">
                <a *ngFor="let link of links" class="scroll" [id]="link.location">{{link.title}}</a>
                <glow-button id="mobile-glow-button" [expand]="true" style="width: 100%;padding: 0px 0px 20px 20px;"></glow-button>
            </div>
        </div>
       
        <div style="float:right; display: flex; align-items: center;">
            <button id="dark-mode-toggle" mat-icon-button aria-label="Dark Mode" (click)="toggleDarkTheme()">
                <mat-icon *ngIf="!isDark">dark_mode</mat-icon>
                <mat-icon *ngIf="isDark" fontSet="material-icons-outlined">light_mode_outlined</mat-icon>
            </button>
            <glow-button *ngIf="goBackPage != 'true'" id="desktop-glow-button"></glow-button>
            <view-my-work-button  
                *ngIf="goBackPage == 'true'" 
                style="position:relative" 
                :reverseArrow="true" 
                :actionText="&nbsp;Back" 
                routerLink='/'
                >
        </view-my-work-button>
            <button *ngIf="goBackPage != 'true'" class="m-0 icon" mat-icon-button href="javascript:void(0);" (click)="toggleNav()">
                <mat-icon>menu</mat-icon>
            </button>
        </div>

    </div>
</nav>
