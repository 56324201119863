<!-- <a
    class="btn-connect relative flex rounded-full border content-center bg-black/20 hover:bg-black/10 transition duration-500 dark:bg-white/20 items-center flex-col flex-nowrap gap-10 h-min justify-center overflow-visible p-px decoration-clone w-fit"
    href="/#pricing"
  >
    <div
      class="btn-content w-auto text-white z-10 bg-black px-4 py-2 rounded-[inherit] dark:bg-black hover:bg-white/10 dark:hover:bg-white/5 dark:text-white flex items-center space-x-2"
    >
      <span>Let's Connect</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-rocket h-4 w-4">
        <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z"></path>
        <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z"></path>
        <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0"></path>
        <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5"></path>
      </svg>
    </div>
    <div class="btn-blur absolute z-0 rounded-[inherit]" style="filter: blur(2px); width: 100%; height: 100%; background: radial-gradient(19.98% 48.76% at 43.73% 91.35%, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%); will-change: auto;"></div>
    <div class="btn-background bg-black absolute z-1 flex-none inset-[2px] rounded-[100px]"></div>
  </a> -->

  <a class="connect-button">
    <span class="background-effect"></span>
    <div class="button-content scroll" id="contact">
        <span class="scroll" id="contact">Let's Connect</span>
        <svg class="scroll" id="contact" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
            <path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z"></path>
            <path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z"></path>
            <path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0"></path>
            <path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5"></path>
        </svg>
    </div>
    <!-- <div class="blur-background"></div> -->
    <div class="inner-border"></div>
</a>
