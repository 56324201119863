import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThemeService } from 'src/services/theme.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ScrollSvgComponent } from './components/scroll-svg/scroll-svg.component';
import { BlobsSvgComponent } from './components/blobs-svg/blobs-svg.component';
import { Phone3dComponent } from './components/phone3d/phone3d.component';
import { GlowButtonComponent } from './components/buttons/glow-button/glow-button.component';
import { ConnectButtonComponent } from './components/buttons/connect-button/connect-button.component';
import { ViewMyWorkButtonComponent } from './components/buttons/view-my-work-button/view-my-work-button.component';
import { Laptop3dComponent } from './components/laptop3d/laptop3d.component';
import { FormsModule } from '@angular/forms';
import { ProjectSectionComponent } from './components/project-section/project-section.component';
import { ExperienceTimelineComponent } from './components/experience-timeline/experience-timeline.component';
import { FooterComponent } from './components/footer/footer.component';
import { SecuritySectionComponent } from './components/security-section/security-section.component';
import { CicdComponent } from './components/cicd/cicd.component';
import { LazyImgDirective } from './directives/LazyImgDirective';
import { ProjectPageComponent } from './components/project-page/project-page.component';
import { ResumePageComponent } from './components/resume-page/resume-page.component';
import { SafeHtmlPipe } from './pipes/safeHtml';
import { LandingComponent } from './components/landing/landing.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ScrollSvgComponent,
    BlobsSvgComponent,
    Phone3dComponent,
    GlowButtonComponent,
    ConnectButtonComponent,
    ViewMyWorkButtonComponent,
    Laptop3dComponent,
    ProjectSectionComponent,
    ExperienceTimelineComponent,
    FooterComponent,
    SecuritySectionComponent,
    CicdComponent,
    LazyImgDirective,
    SafeHtmlPipe,
    ProjectPageComponent,
    ResumePageComponent,
    LandingComponent,
    ContactFormComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [ThemeService],
  bootstrap: [AppComponent],
})
export class AppModule {}
