<div class="container security-section">
    <div class="row security-container">
      <div class="col-md-4 col-sm-6 col-xs-12 p-0" *ngFor="let item of securityPractices" style="border-right: 1px solid #cccccc4a; position: relative;">
        <div class="side-marker"></div>
        <div class="grid-item">
          <mat-icon>{{ item.icon }}</mat-icon>
          <h3>{{ item.title }}</h3>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
  </div>