<footer class="text-center py-4 footer-container controls-container scroll-nav" style="position: relative;">
    <div class="container">
      <div class="d-flex justify-content-center mb-3">
        <a id="about" class="mx-3 scroll">About</a>
        <a id="projects" class="mx-3 scroll">Projects</a>
        <a id="experience" class="mx-3 scroll">Experience</a>
        <a id="contact" class="mx-3 scroll">Contact</a>
      </div>
  
      <div class="mb-3">
        <img [attr.src]="(isDarkTheme | async) ? 'assets/img/Logo.PNG' : 'assets/img/Logo-light.PNG'" alt="Logo" class="logo d-none d-lg-block">
      </div>
  
      <div class="social-icons mb-3">
        <a href="https://github.com/NicolasDeLuca" class="mx-2" aria-label="GitHub">
            <i style="width:20px" class="fa fa-github" aria-hidden="true"></i> 
        </a>
        <a href="https://linkedin.com/in/nicolasdeluca" class="mx-2" aria-label="LinkedIn">
            <i style="width:20px" class="fa fa-linkedin-square" aria-hidden="true"></i> 
        </a>
        <a href="https://devpost.com/nicolasdeluca" class="mx-2" aria-label="Devpost">
            <svg style="width:20px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 280.3 242" enable-background="new 0 0 280.3 242" xml:space="preserve">
                <g id="XMLID_1_">
                    <path id="XMLID_6_" d="M133.7,76H118v90h14.7c30.9,0,45.1-18.1,45.1-45C177.8,90.9,164.9,76,133.7,76z"/>
                    <path id="XMLID_9_" d="M210.2,0H70.1L0,121l70.1,121h140.1l70.1-121L210.2,0z M132.7,195H89V47h45.8c42.1,0,73.3,20.1,73.3,74   C208.1,172.8,170.6,195,132.7,195z"/>
                </g>
            </svg>
        </a>
        <glow-button></glow-button>
      </div>
  
      <div>
        <p>&copy; {{ currentYear }} Nicolas De Luca</p>
      </div>
    </div>
  </footer>
  