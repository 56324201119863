<svg width="50"
class="scroll-svg"
height="50"
viewBox="0 0 100 100"
fill="none"
xmlns="http://www.w3.org/2000/svg"
style="width: 90px;
height: 90px;
margin-top: 80px;">
<style>
.line-mouse2 {
 animation: line-mouse2-scroll 2s infinite;
}

@keyframes line-mouse2-scroll {
 0% {
   transform: translate3d(0, 0, 0);
 }

 50% {
   transform: translate3d(0, 10px, 0);
 }

 100% {
   transform: translate3d(0, 0, 0);
 }
}

@media (prefers-reduced-motion: reduce) {
 .line-mouse2 {
   animation: none;
 }
}
</style>
<g filter="url(#filter0_ii)">
<rect class="fill1"
     x="23"
     y="7"
     width="55"
     height="86"
     rx="27.5"
     fill="rgba(0,0,0,0)"
     stroke-width="2px"></rect>
</g>
<rect class="stroke1"
   x="23"
   y="7"
   width="55"
   height="86"
   rx="27.5"
   stroke="rgba(255,255,255,1)"
   stroke-width="2px"></rect>
<line class="line-mouse2 stroke2"
   x1="51"
   y1="18"
   x2="51"
   y2="33"
   stroke="rgba(255,255,255,1)"
   stroke-width="2px"></line>
<defs>
<filter id="filter0_ii"
       x="20"
       y="2"
       width="63"
       height="94"
       filterUnits="userSpaceOnUse"
       color-interpolation-filters="sRGB">
 <feFlood flood-opacity="0"
          result="BackgroundImageFix"></feFlood>
 <feBlend mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"></feBlend>
 <feColorMatrix in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"></feColorMatrix>
 <feOffset dx="4"
           dy="-4"></feOffset>
 <feGaussianBlur stdDeviation="2"></feGaussianBlur>
 <feComposite in2="hardAlpha"
              operator="arithmetic"
              k2="-1"
              k3="1"></feComposite>
 <feColorMatrix type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"></feColorMatrix>
 <feBlend mode="normal"
          in2="shape"
          result="effect1_innerShadow"></feBlend>
 <feColorMatrix in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"></feColorMatrix>
 <feOffset dx="-4"
           dy="4"></feOffset>
 <feGaussianBlur stdDeviation="1"></feGaussianBlur>
 <feComposite in2="hardAlpha"
              operator="arithmetic"
              k2="-1"
              k3="1"></feComposite>
 <feColorMatrix type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"></feColorMatrix>
 <feBlend mode="normal"
          in2="effect1_innerShadow"
          result="effect2_innerShadow"></feBlend>
</filter>
</defs>
</svg>
