import { Directive, ElementRef, AfterViewInit } from '@angular/core';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

//the img lazy loading interferes with the gsap timing of animations via its scroll trigger height calculations.
// Solution is to refresh scrollTrigger when imgs are loaded.
@Directive({ selector: 'img' })
export class LazyImgDirective implements AfterViewInit {
  constructor(private el: ElementRef<HTMLImageElement>) {}

  ngAfterViewInit() {
    const imgElement: any = this.el.nativeElement;
    const supportsLazyLoading = 'loading' in HTMLImageElement.prototype;

    // Function to refresh ScrollTrigger
    const refreshScrollTrigger = () => {
      ScrollTrigger.refresh();
    };

    if (!supportsLazyLoading) {
      // If native lazy loading is not supported, use IntersectionObserver
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            imgElement.src = imgElement.dataset.src; // Use data-src attribute

            // Listen for the load event after setting the src
            imgElement.addEventListener('load', refreshScrollTrigger);
            observer.disconnect(); // Stop observing once loaded
          }
        });
      });
      observer.observe(imgElement);
    } else {
      imgElement.setAttribute('loading', 'lazy');
      // Listen for the load event for images with native lazy loading
      imgElement.addEventListener('load', refreshScrollTrigger);
    }
  }
}
