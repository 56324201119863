<div class="project-container pb-5">
    <div class="container" style="padding-top: 150px;"  *ngIf="project">
        <div class="row">
            <!-- Left Column for Image and Title -->
            <div class="col-md-5 d-flex flex-column align-items-center mb-3 mb-md-0">
                <img *ngIf="!project.coverVideo; else video" src="{{project.coverImg}}" alt="{{project.title}}">
                <ng-template #video>
                    <video controls [poster]="project.coverImg">
                        <source [src]="project.coverVideo" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </ng-template>
                
                <p class="mt-2 mx-0 text-center">{{project.title}}</p>
            </div>
        
            <!-- Right Column for Project Details -->
            <div class="col-md-7">
                <h2 class="title">{{project.name}}</h2>
                <p class="">{{project.subtitle}}</p>
                <h2 class="mt-4">What tools it uses</h2>
                <div class="d-flex flex-wrap">
                    <div *ngFor="let tool of projectTools" class="tool-item">
                        <div [innerHTML]="tool.html"></div>
                    </div>
                </div>
                <h2 class="mt-4">Links</h2>
                <span><a *ngIf="project.links.default.github" [href]="project.links.default.github" target="_new"><img src="https://img.shields.io/badge/Github-181717.svg?style=for-the-badge&amp;logo=github&amp;logoColor=white" alt="Github"></a>&nbsp;</span>
                <span><a *ngIf="project.links.default.demo" [href]="project.links.default.demo" target="_new"><img src="https://img.shields.io/badge/Demo-00C7B7.svg?style=for-the-badge" alt="Demo"></a>&nbsp;</span>
                <ng-container *ngFor="let link of project.links.extra">
                    <span [innerHtml]="link.html | safeHtml"></span>
                </ng-container>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h2 class="mt-4">How I built it</h2>
                <p [innerHTML]="project.description"></p>
            </div>
        </div>
        <div class="row" *ngFor="let img of project.imgs">
            <div class="col">
              <h2 class="mt-4">{{img.heading}}</h2>
              <img [attr.src]="(isDarkTheme | async) ? (img.url_dark_mode ? img.url_dark_mode : img.url) : img.url">
              <p *ngIf="img.summary != ''" [innerHTML]="img.summary | safeHtml"></p>
            </div>
        </div>
    </div>
</div>
  