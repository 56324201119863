import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { ThemeService } from 'src/services/theme.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public isDarkTheme!: Observable<boolean>;
  @Input() goBackPage: string = 'false';
  constructor(private themeService: ThemeService, private router: Router) {}
  public isDark: boolean = false;
  private subscriptions = new Subscription();
  public links: any = [
    {
      title: 'About',
      location: 'about',
    },
    {
      title: 'My Work',
      location: 'phone',
    },
    {
      title: 'Experience',
      location: 'experience',
    },
    {
      title: 'Contact',
      location: 'contact',
    },
  ];

  ngOnInit(): void {
    this.isDarkTheme = this.themeService.isDarkTheme;

    const routerSub = this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url === '/') {
          // Only re-bind scroll links when back on the main page
          setTimeout(() => ScrollTrigger.refresh(), 0);
        }
      });
    this.buildScrollLinks();
    this.subscriptions.add(routerSub);
  }

  ngAfterViewInit() {
    window.addEventListener('DOMContentLoaded', this.onDomContentLoaded);
  }
  private onDomContentLoaded = (e: Event) => {
    this.buildScrollLinks();
    this.toggleDarkTheme();
  };

  ngOnDestroy() {
    window.removeEventListener('DOMContentLoaded', this.onDomContentLoaded);
    this.subscriptions.unsubscribe(); // Unsubscribe from all subscriptions
  }

  buildScrollLinks() {
    console.log('bulding scroll');
    let links = document.getElementsByClassName('scroll');
    for (let i = 0; i < links.length; i++) {
      var l = links[i];
      l.addEventListener('click', function (e: any) {
        document
          .querySelector('.' + e?.target?.id)!
          .scrollIntoView({ behavior: 'smooth' });
        // n.classList.remove("responsive");
      });
    }
  }

  toggleNav() {
    var e = document.getElementById('myTopnav');
    if (!e) return;
    e.classList.contains('responsive')
      ? e.classList.remove('responsive')
      : (e.className += ' responsive');
  }

  toggleDarkTheme() {
    this.themeService.setDarkTheme(!this.isDark);
    this.isDark = !this.isDark;
    var e = document.getElementById('myTopnav');
    if (!e) return;
    e.classList.contains('dark')
      ? e.classList.remove('dark')
      : (e.className += ' dark');
  }
  toTop(): void {
    const element = document.querySelector('.landing');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  // toggleDarkThemeToggle(checked: boolean) {
  //   this.themeService.setDarkTheme(checked);
  //   var e = document.getElementById('myTopnav');
  //   if (!e) return;
  //   e.classList.contains('dark')
  //     ? e.classList.remove('dark')
  //     : (e.className += ' dark');
  // }
}
