import { Component } from '@angular/core';

@Component({
  selector: 'security-section',
  templateUrl: './security-section.component.html',
  styleUrls: ['./security-section.component.scss'],
})
export class SecuritySectionComponent {
  securityPractices = [
    {
      icon: 'security', // Material icon for security
      title: 'API Validation & Sanitization',
      description:
        'Implement input validation and sanitization to prevent attacks like XSS and SQL injection.',
    },
    {
      icon: 'vpn_key',
      title: 'JWT Authentication',
      description:
        'Securely implement user authentication using JSON Web Tokens (JWT) with refresh tokens.',
    },
    {
      icon: 'domain_verification',
      title: 'CSRF Protection',
      description:
        'Implemented CSRF tokens to ensure secure client-server communication.',
    },
    {
      icon: 'lock',
      title: 'AES-256 Encryption',
      description:
        'Encrypt sensitive data in transit and at rest using industry-standard AES-256 encryption.',
    },
    {
      icon: 'visibility_off',
      title: 'Secret Management',
      description:
        'Centralized secrets management using HashiCorp Vault and Thycotic to securely handle credentials.',
    },
    {
      icon: 'verified_user',
      title: 'User-Based Access Control',
      description:
        'Built and extended user access control systems with granular RBAC permissions.',
    },
  ];
}
