import { Component } from '@angular/core';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'cicd',
  templateUrl: './cicd.component.html',
  styleUrls: ['./cicd.component.scss'],
})
export class CicdComponent {
  stages = [
    {
      title: 'Source Control',
      header: 'fa code-fork',
      details: ['Commit code to the repository.', 'Create a pull request.'],
      icon: 'fa fa-code-fork',
      id: 'stage1',
      progress: 1,
    },
    {
      title: 'Build',
      header: 'Build Process',
      details: ['Compile source code.', 'Create binaries or packages.'],
      icon: 'fa fa-building',
      id: 'stage2',
      progress: 100,
    },
    {
      title: 'Test',
      header: 'Testing Phase',
      details: ['Run unit tests.', 'Perform integration testing.'],
      icon: 'fa fa-flask',
      id: 'stage3',
      progress: 260,
    },
    {
      title: 'Deploy',
      header: 'Deployment Phase',
      details: [
        'Deploy to staging environment.',
        'Deploy to production environment.',
      ],
      mat_icon: 'dns',
      id: 'stage4',
      progress: 380,
    },
    {
      title: 'Monitor & Feedback',
      header: 'Monitoring',
      details: ['Collect user feedback.', 'Monitor application performance.'],
      mat_icon: 'assessment',
      id: 'stage5',
      progress: 450,
    },
  ];

  public progressValue: number = 0;
  private timelineItems: any = [];
  private appContainer: HTMLElement | null = null;
  private offset = 0.8;
  private lastScrollTop = 0;

  ngAfterViewInit() {
    this.appContainer = document.querySelector('.app-container');
    this.timelineItems = document.querySelectorAll('.cicd-item');

    this.hideBlocks();
    this.appContainer?.addEventListener('scroll', this.onScroll.bind(this));
    const timelineHeightLimit = this.calculateTimelineHeightLimit();
    const timelineElement = document.querySelector(
      '#ci-cd-timeline'
    ) as HTMLElement;
    // Initialize GSAP ScrollTrigger
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('#ci-cd-timeline', {
      scrollTrigger: {
        trigger: '.cicd-container',
        start: 'top top+=60%',
        end: 'bottom bottom',
        scrub: true,
        // markers: true,
        scroller: '.app-container',
        onUpdate: (self) => {
          this.progressValue = Math.round(self.progress * timelineHeightLimit);
          timelineElement.style.setProperty(
            '--line-width',
            `${self.progress * timelineHeightLimit}px`
          );
        },
      },
    });
  }

  ngOnDestroy() {
    this.appContainer?.removeEventListener('scroll', this.onScroll.bind(this));
  }

  // Calculate the height limit for the timeline
  private calculateTimelineHeightLimit(): number {
    const ciCdWindow: any = document.querySelector('.software-design');
    return (
      ciCdWindow?.clientWidth - (window.innerWidth > 650 ? 950 : -750) || 0
    );
  }

  hideBlocks(): void {
    this.timelineItems.forEach((item: any) => {
      const rect = item.getBoundingClientRect();
      if (rect.top > window.innerHeight * this.offset) {
        item.querySelector('.timeline-icon')?.classList.add('is-hidden');
        item.querySelector('.timeline-content')?.classList.add('is-hidden');
      }
    });
  }

  showBlocks(isScrollingDown: boolean): void {
    this.timelineItems.forEach((item: any) => {
      const rect = item.getBoundingClientRect();
      const containerRect = this.appContainer!.getBoundingClientRect();
      const isInViewScrollDown =
        rect.top <= containerRect.bottom * this.offset &&
        rect.bottom >= containerRect.top;
      const completelyOutOfView =
        rect.bottom < containerRect.top || rect.top > containerRect.bottom;

      // if (isInViewScrollDown) {
      //   // item.querySelector('.timeline-icon')?.classList.remove('is-hidden');
      //   // item.querySelector('.timeline-content')?.classList.remove('is-hidden');
      //   // if (isScrollingDown) {
      //   //   item.querySelector('.timeline-icon')?.classList.add('animate-it');
      //   //   item.querySelector('.timeline-content')?.classList.add('animate-it');
      //   // }
      // } else {
      //   if (completelyOutOfView) {
      //     item.querySelector('.timeline-icon')?.classList.add('is-hidden');
      //     item.querySelector('.timeline-content')?.classList.add('is-hidden');
      //   }
      //   if (
      //     !isScrollingDown &&
      //     completelyOutOfView
      //     // &&
      //     // rect.top >= containerRect.bottom &&
      //     // rect.bottom < containerRect.bottom
      //   ) {
      //     item.querySelector('.timeline-icon')?.classList.remove('animate-it');
      //     item
      //       .querySelector('.timeline-content')
      //       ?.classList.remove('animate-it');
      //   }
      // }
    });
  }

  onScroll(): void {
    const currentScrollTop = this.appContainer?.scrollTop || 0;
    const scrollingDown = currentScrollTop > this.lastScrollTop;
    this.lastScrollTop = currentScrollTop;

    requestAnimationFrame(() => this.showBlocks(scrollingDown));
  }
}
