import { Component, AfterViewInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'experience-timeline',
  templateUrl: './experience-timeline.component.html',
  styleUrls: ['./experience-timeline.component.scss'],
  animations: [
    trigger('bounce', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate('600ms ease-out', style({ transform: 'scale(1)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class ExperienceTimelineComponent implements AfterViewInit {
  private timelineItems: any = [];
  private appContainer: HTMLElement | null = null;
  private offset = 0.8;
  private lastScrollTop = 0; // Track the last scroll position

  ngAfterViewInit() {
    this.appContainer = document.querySelector('.app-container');
    this.timelineItems = document.querySelectorAll('.timeline-item');

    // Hide blocks initially
    this.hideBlocks();
    // Add scroll event listener to the app-container element
    this.appContainer?.addEventListener('scroll', this.onScroll.bind(this));
    const experienceWindow: any = document.querySelector('.experience');

    let timeline_height_limit =
      experienceWindow.clientHeight - (window.innerWidth > 650 ? 750 : 775);
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('#timeline', {
      scrollTrigger: {
        trigger: '#timeline',
        start: 'top top+=600',
        end: 'bottom-=60%',
        scrub: true,
        // markers: true,
        scroller: '.app-container',
        onUpdate: function (self) {
          const timelineElement = document.querySelector(
            '#timeline'
          ) as HTMLElement | null;
          if (timelineElement) {
            // console.log('progressing height');
            // Update the CSS variable based on scroll progress
            timelineElement.style.setProperty(
              '--line-height',
              `${self.progress * timeline_height_limit}px`
            );
          }
        },
      },
    });
  }

  // Hide timeline blocks outside the viewport
  hideBlocks(): void {
    this.timelineItems.forEach((item: HTMLElement) => {
      const rect = item.getBoundingClientRect();
      if (rect.top > window.innerHeight * this.offset) {
        item.querySelector('.timeline-icon')?.classList.add('is-hidden');
        item.querySelector('.timeline-content')?.classList.add('is-hidden');
      }
    });
  }

  // Show timeline blocks that enter the viewport
  showBlocks(isScrollingDown: boolean): void {
    this.timelineItems.forEach((item: HTMLElement) => {
      const rect = item.getBoundingClientRect();
      const containerRect = this.appContainer!.getBoundingClientRect(); // Get the container's bounding rect
      const isInViewScrollDown =
        rect.top <= containerRect.bottom * this.offset &&
        rect.bottom >= containerRect.top; // Ensure it's also within the top of the container
      const completelyOutOfView =
        rect.bottom < containerRect.top || rect.top > containerRect.bottom;

      if (isInViewScrollDown) {
        item.querySelector('.timeline-icon')?.classList.remove('is-hidden');
        item.querySelector('.timeline-content')?.classList.remove('is-hidden');
        item.querySelector('.time-stamp')?.classList.remove('is-hidden');
        if (
          isScrollingDown &&
          rect.top <= containerRect.bottom &&
          rect.bottom > containerRect.bottom
        ) {
          item.querySelector('.timeline-icon')?.classList.add('animate-it');
          item.querySelector('.timeline-content')?.classList.add('animate-it');
          item.querySelector('.time-stamp')?.classList.add('animate-it');
        }
      } else {
        if (completelyOutOfView) {
          item.querySelector('.timeline-icon')?.classList.add('is-hidden');
          item.querySelector('.timeline-content')?.classList.add('is-hidden');
          item.querySelector('.time-stamp')?.classList.add('is-hidden');
        }
        if (!isScrollingDown && completelyOutOfView) {
          item.querySelector('.timeline-icon')?.classList.remove('animate-it');
          item.querySelector('.time-stamp')?.classList.remove('animate-it');
          item
            .querySelector('.timeline-content')
            ?.classList.remove('animate-it');
        }
      }
    });
  }

  // Scroll event handler
  onScroll(): void {
    const currentScrollTop = this.appContainer?.scrollTop || 0; // Get the current scroll position

    // Determine if scrolling down
    const scrollingDown = currentScrollTop > this.lastScrollTop;

    // Update the last scroll position
    this.lastScrollTop = currentScrollTop;

    // Using requestAnimationFrame for better performance
    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(() => this.showBlocks(scrollingDown));
    } else {
      setTimeout(() => this.showBlocks(scrollingDown), 100);
    }
  }
}
