
      <svg id="svgRoute"
           preserveAspectRatio="none"
           style="opacity: 0.85; 
           pointer-events: none; 
           width:100%;
           height:100%;
          "
           viewBox="0 0 2500 6692"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink"
           version="1.1">
            <path id="path"
                  d="M 0 0 M 652 0 V 239.5 V 317.5 C 2997 1650 151 414 -214 989 C -550 1451 -46 1640 227 1776 C 531 1975 2546 2301 174 2857 C -1411 4043 1835 3572 1522 4231 C 1142 5369 -707 4431 -435 4914 C 524 6961 764.5 4537 804 6755"
                  fill="none" 
                  style="position:absolute; transform:translateX(calc(50% - 547px))"/>

            <g transform="translate(1400.2471619697132 175.44550568608634)"
               style="pointer-events: auto; cursor:pointer">
                  <path id="blob99"
                        d="M92.1 -92.8C125.3 -58.9 162.1 -29.5 176.9 14.7C191.6 58.9 184.2 117.9 151 154.4C117.9 190.9 58.9 204.9 5.7 199.3C-47.6 193.6 -95.2 168.2 -124.9 131.7C-154.6 95.2 -166.3 47.6 -168.3 -2C-170.3 -51.6 -162.6 -103.2 -132.9 -137.1C-103.2 -170.9 -51.6 -187 -11.1 -175.9C29.5 -164.8 58.9 -126.6 92.1 -92.8"
                        fill="#76d7e8"></path>
                        <g id="blobtext">
                              <text 
                              style="fill: rgb(255, 255, 255); pointer-events:none; font-weight:500; transform: scaleY(1.2); font-size: 45px; white-space: pre;"
                              x="-120.435"
                              y="20.162">Click to begin </text>
                        </g>
                  
            </g>
            <g transform="translate(449.9907378730831 250.44410534930566)"
               style="visibility: hidden; pointer-events: auto">
                  <path id="blob2"
                        d="M153.7 -141.7C190 -117.4 204 -58.7 195.2 -8.8C186.3 41 154.7 82 118.4 111.9C82 141.7 41 160.3 5.8 154.6C-29.5 148.8 -58.9 118.6 -100.6 88.8C-142.3 58.9 -196.1 29.5 -197.7 -1.5C-199.2 -32.5 -148.4 -65.1 -106.7 -89.4C-65.1 -113.7 -32.5 -129.9 13.1 -142.9C58.7 -156 117.4 -166 153.7 -141.7"
                        fill="#76d7e8"></path>
            </g>
      </svg>
