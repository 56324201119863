<div #laptopCanvasContainer class="elementreflap">

    <!-- Slider to control the rotation -->
     <div *ngIf='false' style="position: fixed; bottom: 0; left: 0; z-index: 99;">
        <!-- <input style="z-index: 99;position: relative;"
            type="range"
            min="-1"
            max="1"
            step="0.01"
            [(ngModel)]="laptopYPos"
            (input)="onSlideLaptopYChange($event)"
        /> {{laptopYPos}}
         -->
        camera rotation<br>
        <input style="z-index: 99;position: relative;"
          type="range"
          min="0"
          max="360"
          step="1"
          [(ngModel)]="rotationValue"
          (input)="onSliderChange($event)"
        /> {{rotationValue}}
        
        <!-- <input style="z-index: 99;position: relative;"
            type="range"
            min="0"
            max="360"
            step="1"
            [(ngModel)]="rotationlapXValue"
            (input)="onSliderRotationlapXValueChange($event)"
        /> {{rotationlapXValue}} -->

        <div class="d-flex flex-column">
            <div class="d-flex flex-row">
                camera X:
                <input style="z-index: 99;position: relative;"
                type="range"
                min="-1"
                max="1"
                step="0.01"
                [(ngModel)]="positionXValue"
                (input)="onSliderXChange($event)"
            />{{positionXValue}}
            </div>
            <div class="d-flex flex-row">
                camera Y:
                <input style="z-index: 99;position: relative;"
                type="range"
                min="-1"
                max="1"
                step="0.01"
                [(ngModel)]="positionYValue"
                (input)="onSliderYChange($event)"
            />{{positionYValue}}
            </div>
            <div class="d-flex flex-row">
                camera Z:
                <input style="z-index: 99;position: relative;"
                type="range"
                min="-1"
                max="1"
                step="0.01"
                [(ngModel)]="positionZValue"
                (input)="onSliderZChange($event)"
            />{{positionZValue}}
            </div>
        </div>

        <div class="d-flex flex-column">
            <div class="d-flex flex-row">
                light X:
                <input style="z-index: 99;position: relative;"
                type="range"
                min="-1"
                max="1"
                step="0.01"
                [(ngModel)]="lightX"
                (input)="lightXChange($event)"
            />{{lightX}}
            </div>
            <div class="d-flex flex-row">
                light Y:
                <input style="z-index: 99;position: relative;"
                type="range"
                min="-1"
                max="1"
                step="0.01"
                [(ngModel)]="lightY"
                (input)="lightYChange($event)"
            />{{lightY}}
            </div>
            <div class="d-flex flex-row">
                light Z:
                <input style="z-index: 99;position: relative;"
                type="range"
                min="-1"
                max="1"
                step="0.01"
                [(ngModel)]="lightZ"
                (input)="lightZChange($event)"
            />{{lightZ}}
            </div>
        </div>

        <div class="d-flex flex-column">
            <div class="d-flex flex-row">
                light distance X:
                <input style="z-index: 99;position: relative;"
                type="range"
                min="-1"
                max="1"
                step="0.01"
                [(ngModel)]="lightdistance"
                (input)="lightdistanceChange($event)"
            />{{lightdistance}}
            </div>
            <div class="d-flex flex-row">
                light intensity Y:
                <input style="z-index: 99;position: relative;"
                type="range"
                min="-1"
                max="1"
                step="0.01"
                [(ngModel)]="lightintensity"
                (input)="lightintensityChange($event)"
            />{{lightintensity}}
            </div>
        </div>
        
     </div>

     <div class="controls-container scroll-nav align-items-center">
        <!-- <button (click)="toggleLid()">{{isLidOpen ? 'Close Laptop' : 'Open Laptop'}}</button> -->
        <glow-button style="padding: 10px;" (click)="toggleLid()" :url='' :actionText="{{isLidOpen ? 'Close' : 'Open'}}"></glow-button>
        <view-my-work-button class="next-btn" :actionText="&nbsp;Next" (click)="nextVideo()"></view-my-work-button>
      </div>
    <canvas #laptopCanvas class="laptop-canvas"></canvas>
  </div>
  