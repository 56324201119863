export const projects = [
  {
    id: '1',
    legacy_key: 'ecommerce-backend-api.html',
    title: 'Fully Documented and Authenticated REST API',
    name: 'Ecommerce API',
    subtitle:
      'A secure eCommerce REST API featuring JWT authentication and role-based access control (RBAC), with comprehensive OpenAPI (Swagger) documentation, API request validation using class-validator for type safety, and a custom logging filter for comprehensive API monitoring.',
    description: `
        <div>        
            This eCommerce API was developed using NestJS, TypeScript, and Express, with a focus on providing a secure and scalable backend for web applications. Key features and deployment details include:

            <ul class="mini-text">
                <li><strong>Backend Framework</strong>: Built with NestJS, leveraging TypeScript and Express for robust and scalable API development.</li>
                <li><strong>Security</strong>: Includes both authorized routes for privileged users and unauthenticated routes accessible to all users. Implemented class-validator for request validation, ensuring type safety and informative error responses.</li>
                <li><strong>Logging</strong>: Features a custom logging filter to record all API requests and capture exceptions. Logs are available with pagination on admin routes for detailed monitoring and analysis.</li>
                <li><strong>Documentation</strong>: Each API route is documented using the OpenAPI (Swagger) standard, and the entire application is further detailed with Compodoc, providing comprehensive inline comments and structure explanations.</li>
                <li><strong>Deployment</strong>: 
                    <ul>
                        <li>Deployed on Hetzner servers using Docker Compose and managed through Coolify for automated deployments.</li>
                        <li>Utilizes Cloudflare for CDN, SSL, DNS, and DDoS protection, with the frontend also hosted on Cloudflare Pages.</li>
                        <li><strong>CI/CD</strong>: Integrated with GitHub Actions for automated build, containerization, and deployment. The CI/CD pipeline builds the application, creates a Docker container, and pushes it to GitHub Container Registry. Coolify then deploys the latest image from the registry using a webhook.</li>
                        <li><strong>Load Balancing</strong>: Employs Traefik for load balancing between Docker containers, with configuration files stored on the server and mounted as Docker volumes to manage container ephemerality.</li>
                        <li><strong>Health Checks</strong>: Docker Compose files include scheduled health checks to ensure container reliability.</li>
                    </ul>
                </li>
            </ul>
            
            This setup enhances deployment efficiency, improves application reliability, and supports scalable growth, making it a comprehensive solution for modern eCommerce needs.

        </div>`,
    coverImg: '/assets/gifs/ecommerce-api-2.gif',
    imgs: [
      {
        heading: 'Architecture Diagram',
        url: 'assets/img/project-imgs/architecture-diagram-lightmode.png',
        url_dark_mode:
          'assets/img/project-imgs/architecture-diagram-darkmode.png',
        summary: `  
          <ol class="ecom-api-summary" style="counter-reset: item; list-style-type: none; margin-left: -25px; width: 100%; max-width: 1000px;">
            <li style="margin: 10px 0; padding-left: 35px; position: relative; text-align: left;">
              <strong>Coolify</strong>: Manages the deployment of Docker containers on the Hetzner server, ensuring that the latest versions of the applications are running.
            </li>
            <li style="margin: 10px 0; padding-left: 35px; position: relative; text-align: left;">
              <strong>Hetzner Server</strong>: Hosts the entire infrastructure, including Docker Compose, Traefik load balancer, and MySQL database.
            </li>
            <li style="margin: 10px 0; padding-left: 35px; position: relative; text-align: left;">
              <strong>Traefik Proxy Load Balancer</strong>: Distributes incoming traffic across multiple instances of the eCommerce API containers, ensuring high availability and reliability.
            </li>
            <li style="margin: 10px 0; padding-left: 35px; position: relative; text-align: left;">
              <strong>Docker Compose</strong>: Orchestrates the deployment of multiple containers, including the eCommerce API and MySQL database. Utilizes Docker volumes for persistent storage and performs regular health checks to ensure container stability.
            </li>
            <li style="margin: 10px 0; padding-left: 35px; position: relative; text-align: left;">
              <strong>GitHub Actions CICD Pipeline</strong>: Automates the build and deployment process. Upon pushing code to the repository, the pipeline builds the Docker images, pushes them to the GitHub Container Registry, and triggers Coolify to redeploy the updated containers.
            </li>
            <li style="margin: 10px 0; padding-left: 35px; position: relative; text-align: left;">
              <strong>GitHub Container Registry</strong>: Stores the Docker images of the application, allowing for version control and easy deployment of the latest builds.
            </li>
            <li style="margin: 10px 0; padding-left: 35px; position: relative; text-align: left;">
              <strong>Cloudflare</strong>: Provides a Content Delivery Network (CDN) for faster content delivery, DDoS protection, DNS resolution, and SSL certificates for secure communication.
            </li>
            <li style="margin: 10px 0; padding-left: 35px; position: relative; text-align: left;">
              <strong>Cloudflare Pages</strong>: Hosts the frontend of the eCommerce application, ensuring that users can access the web pages quickly and securely.
            </li>
          </ol>
          `,
      },
      {
        heading: 'Dockerfile',
        url: 'assets/img/project-imgs/docker.png',
        summary: '',
      },
      {
        heading: 'Docker Compose',
        url: 'assets/img/project-imgs/docker-compose.png',
        summary: '',
      },
      {
        heading: 'Github Actions Yaml',
        url: 'assets/img/project-imgs/actions.png',
        summary: '',
      },
      {
        heading: 'Traefik Config',
        url: 'assets/img/project-imgs/traefik.png',
        summary: '',
      },
    ],
    links: {
      default: {
        github:
          'https://github.com/NickDeLu/backend-template/tree/ecommerce-template',
        demo: 'https://spookyscarystore.shoptree.ca/',
      },
      extra: [
        {
          html: '<a href="https://api.shoptree.ca/api/swagger" target="_new"><img style="border-radius: 32px;" src="https://img.shields.io/badge/OpenAPI%20Swagger-E70C1A.svg?style=for-the-badge&amp;logo=NestJS&amp;logoColor=white" alt="OpenAPI Swagger"></a>&nbsp;',
        },
        {
          html: '<a href="https://api.shoptree.ca/" target="_new"><img style="border-radius: 32px;" src="https://img.shields.io/badge/Combodocs-00B8D9.svg?style=for-the-badge&amp;logo=Combodocs&amp;logoColor=white" alt="Combodocs"></a>&nbsp;',
        },
      ],
    },
    tools: [
      'NestJS',
      'VueJS',
      'MySQL',
      'Stripe',
      'TypeScript',
      'NodeJS',
      'Express',
      'OpenAPI Swagger',
      'Combodocs',
      'Hetzner',
      'Docker',
      'Coolify',
      'GitHub Actions',
      'Cloudflare',
      'Traefik',
      'PassportJS',
    ],
  },

  {
    id: '2',
    legacy_key: 'delunico-drive.html',
    title: 'React Google Drive Clone',
    name: 'Delunico Drive',
    subtitle:
      'A React.js web app that implements file storage through the Google Firebase API, featuring user auth for secure file management, allowing users to upload, download, and organize files in directories linked to their accounts.',
    description: `
    <p>To build Delunico Drive, I developed a React web application that enables users to store, download and share their files through a Firebase API. The app incorporates several technologies such as ReactJS, Node.js, Bootstrap, and Firebase, with data being hosted on Google Firebase and accessed via its API. User authentication and password recovery features are included, with the option for sharing download and viewing links to their files. For user data protection, the app incorporates HTTPS SSL certificates, encryption of passwords using a hash algorithm, and authorization controls to prevent unauthorized access to other user's files. The front-end is now deployed on railway.app, while the project was formerly hosted on the Heroku web server. The application utilizes the MVC design pattern, RESTful service layer, and Single Page Application (SPA) with CRUD functionalities that allow users to upload, create directories and subdirectories, and update their account information.</p>
   `,
    coverImg: '/assets/gifs/delunico-drive-2.gif',
    imgs: [],
    links: {
      default: {
        github: 'https://github.com/NickDeLu/delunico-google-drive-clone',
        demo: 'https://delunico-drive.netlify.app/',
      },
    },
    tools: [
      'React',
      'NodeJS',
      'Bootstrap',
      'Firebase',
      'HTML',
      'CSS',
      'TypeScript',
      'Go',
      'Netlify',
    ],
  },

  {
    id: '3',
    legacy_key: 'task-manager.html',
    title: 'Angular and Nodejs CRUD App',
    name: 'Task Manager',
    subtitle:
      'A task management web application that utilizes full CRUD operations through a RESTful API, seamlessly connecting an Angular frontend with a Node.js Express backend and a MySQL database for efficient task management.',
    description: `I developed a task management web application using full CRUD operations with Angular and a Node.js API connected to a MySQL database. The application offers task management features with the option to save multiple lists. The REST API was created using the node module express on a Node.js server, with all requests for GET, POST, PUT, and DELETE routed to database queries that perform CRUD operations on a CLEARDB MySQL database add-on hosted on Heroku. The app implements HTTPS TLS encryption to ensure privacy protection for personal tasks or lists. The front-end design was built using Angular web framework with MaterialUI libraries and TypeScript, JavaScript, HTML, and CSS languages. The application was deployed on railway.app with CICD automatic deploys connected to the repository's main branch. Design patterns implemented include MVC, Restful Service Layer, and Single Page Application (SPA). Features include cross off tasks, editing, and deletion of lists and tasks, with the sidebar highlighting the current active list loaded. The application is responsive to most viewports using CSS @media selectors to change font-size, margins, and layout depending on the device's dimensions such as mobile, tablet, or desktop.
 `,
    coverImg: '/assets/gifs/task-manager.gif',
    links: {
      default: {
        github: 'https://github.com/NickDeLu/delunico-taskmanager',
      },
    },
    tools: [
      'TypeScript',
      'Angular',
      'NodeJS',
      'Express',
      'MySQL',
      'MaterialUI',
    ],
  },

  {
    id: '4',
    title: 'Course Project IOS Development - 2022',
    name: 'Quixercise',
    subtitle:
      'A user-friendly exercise app that enables users to quickly search for exercises by body part, target muscle, equipment, or name, view GIF demonstrations, and save exercises for easy access on any iOS device.',
    description: `Quixercise is a sleek and intuitive exercise app that was designed with the purpose of offering users a quick solution to finding new exercises. It was created with Swift storyboard in Xcode, with the design starting off as wireframes in Adobe XD. These wireframes were then exported with Zeplin.io and seamlessly imported into XCode. The app uses ExerciseDB API, hosted on RapidAPI, to fetch exercises. Quixercise's backend is powered by NodeJS and an Express API, which manages user authentication, account information, and saved exercises.
 `,
    coverImg: '/assets/gifs/quixercise.gif',
    links: {
      default: {
        github: 'https://github.com/Quixercise',
      },
      extra: [
        {
          html: '<a href="https://docs.google.com/presentation/d/1B1aJnWVddHLXSJGJM6KMrP1EdB7_e44BG6B92bm_E_8/edit?usp=sharing" target="_new"><img style="border-radius: 32px;" src="https://img.shields.io/badge/-Google%20Slides-FBB917.svg?style=for-the-badge&amp;logo=Google%20Slides&amp;logoColor=white" alt="Google Slides"></a>&nbsp;',
        },
        {
          html: '<a href="https://apps.apple.com/tt/app/quixercise/id1620436033?platform=iphone" target="_new"><img style="border-radius: 32px;" src="https://img.shields.io/badge/-Available%20on%20the%20App%20Store-2E9AFE.svg?style=for-the-badge&amp;logo=Apple&amp;logoColor=white" alt="Available on the App Store"></a>&nbsp;',
        },
      ],
    },
    tools: [
      'Swift UI',
      'Zeplin.io',
      'XCode',
      'Adobe XD',
      'RapidAPI',
      'ExerciseDB',
      'NodeJS',
      'Express',
    ],
  },

  {
    id: '5',
    title: 'Python Bubble and Quick Sort Visualization Tool',
    name: 'Sorting Algorithm Visualizer',
    subtitle:
      'A Pygame app that visualizes bubble sort and quick sort algorithms, providing an interactive experience that helps users understand sorting mechanisms through real-time visualization.',
    description: `
      <p>The Sorting Algorithms app is a visual demonstration of two commonly used sorting algorithms, bubble sort and quick sort, using Pygame and Python. With the use of customized UI components such as sliders and toggle buttons, the app allows users to experiment with the sorting algorithms and get a hands-on understanding of how they work. The app also features window resizing capabilities, where the number of columns is calculated to fit the width and height of the user's screen.<br></br>
                        As the developer, I was able to incorporate my ability to self-teach and learn a new language into this project. Learning how to code in Python was a new experience for me, and this project allowed me to put my newly learned skills to the test. I was able to learn how to use Pygame and Python, and design custom GUI components that matched the overall aesthetic of the app.<br></br>
                        This project was a fun passion project, which I was able to complete in just a couple of days. By building this app, I was able to gain a deeper understanding of sorting algorithms, how they work, and how they can be used in various applications. Overall, the Sorting Algorithms app was a great learning experience for me, allowing me to build my skills and explore the possibilities of Python and Pygame.</p>      
  `,
    coverImg: '/assets/gifs/sortingalgorithms.gif',
    links: {
      default: {
        github: 'https://github.com/NickDeLu/SortingAlgorithms.git',
      },
    },
    tools: ['Python', 'Pygame', 'Quick Sort', 'Bubble Sort', 'Atom'],
  },

  {
    id: '6',
    title: 'UofTHacks X Submission',
    name: 'Ollie AI',
    subtitle:
      "An AI assistant designed to detect, translate, and summarize large amounts of text from images, leveraging Cohere's Generate API for advanced text processing capabilities.",
    description: `Ollie was designed and conceptualized on Figma, then coded using HTML/CSS, JavaScript and Cohere as an API. Our tech stack is simply React for the front end and node.js for the back end. To achieve our translation and summarization we are chaining a pipeline of API requests from different providers to get a seamless unique user experience. First, to get the text, we used either Google Cloud’s vision API for image-to-text detection or a textfield for direct text input. Next, we took that text and translated it to the desired language using Google Cloud’s translation API for text language translations. Finally, we utilized the Cohere AI Javascript SDK to request their generated API for text summarizations and then presented that text to the user in various levels of verbosity.
 `,
    coverImg: '/assets/gifs/ollieai.PNG',
    coverVideo: 'assets/mp4s/Ollie.mp4',
    imgs: [
      {
        heading: 'Figma Wireframe Demo',
        url: '',
        summary:
          '<iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://embed.figma.com/proto/6b7L7mUtXoNe8ikz2cQvGl/Ollie?node-id=23-117&node-type=canvas&scaling=scale-down&content-scaling=fixed&page-id=22%3A85&starting-point-node-id=23%3A117&embed-host=share" allowfullscreen></iframe>',
      },
    ],
    links: {
      default: {
        github: 'https://github.com/NickDeLu/ollie',
        demo: 'https://ollieai.netlify.app/',
      },
      extra: [
        {
          html: '<a href="https://devpost.com/software/ollie" target="_new"><img style="border-radius: 32px;" src="https://img.shields.io/badge/Devpost-008080.svg?style=for-the-badge&amp;logo=devpost&amp;logoColor=white" alt="Devpost"></a>&nbsp;',
        },
        {
          html: '<a href="https://www.figma.com/proto/6b7L7mUtXoNe8ikz2cQvGl/Ollie?page-id=22%3A85&amp;node-id=23%3A117&amp;viewport=390%2C11%2C0.25&amp;scaling=scale-down&amp;starting-point-node-id=23%3A117&amp;show-proto-sidebar=1" target="_new"><img style="border-radius: 32px;" src="https://img.shields.io/badge/Figma-FF7C00.svg?style=for-the-badge&amp;logo=figma&amp;logoColor=white" alt="Figma"></a>&nbsp;',
        },
      ],
    },
    tools: [
      'React',
      'Vite',
      'Google Cloud Platform',
      'Translate API',
      'Vision API',
      'Netlify',
      'Cohere API',
    ],
  },

  {
    id: '7',
    legacy_key: 'thefoodchain.html',
    title: 'Sheridan 2022 Capstone Showcase - 3rd Place',
    name: 'The Food Chain',
    subtitle:
      'By wielding Blockchain technology, this food delivery app effectively "removes" the middleman that charges service fees and puts money back in the pockets of our delivery drivers, customers, and even restaurants!',
    description: `
  On the front end we used React as a web client to connect to the Ethereum Blockchain to substitute the server application, which exists solely to keep the continuous, uninterrupted, and immutable state machine. It then goes down to Chainlink, feeding Ethereum the off-grid data it requires to create consensus using logic that is hard-coded for everyone to see and agree upon, which are Smart Contracts. Because our application is unique to our specifications, we had to develop custom NodeJS services that help handle data stored on MongoDB, using BigchainDB with the help of Tendermint.
 `,
    coverImg: 'assets/img/project-imgs/thefoodchain.png',
    coverVideo: 'assets/mp4s/thefoodchain.mp4',
    imgs: [
      {
        heading: 'Slidedeck Presentation',
        url: '',
        url_dark_mode: '',
        summary: `
        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQKkwXxOTeWCv1uGCZs0IYE4G4Vx45nR2xocPzsye7C-Sgnd-Q-cjL3yyY0ieBbHN1TTKe0lNzJwk3N/embed?start=true&loop=true&delayms=3000" frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
        `,
      },
    ],
    links: {
      default: {
        github: 'https://github.com/Deliverless',
        demo: 'http://thefoodchain.azurewebsites.net/',
      },
      extra: [
        {
          html: '<a href="https://docs.google.com/presentation/d/1R9CiNhFtdmVf43lJY7PRgsUqV0cMIITzOdc_R5tRGYE/edit?usp=sharing" target="_new"><img style="border-radius: 32px;" src="https://img.shields.io/badge/-Google%20Slides-FBB917.svg?style=for-the-badge&amp;logo=Google%20Slides&amp;logoColor=white" alt="Google Slides"></a>&nbsp;',
        },
      ],
    },
    tools: [
      'React',
      'Solidity',
      'Ethereum',
      'BigchainDB',
      'Tendermint',
      'MongoDB',
      'Chainlink',
      'Mapbox API',
      'Geoapify',
      'Stripe',
    ],
  },
];

// {
//   id: '0',
//   title: '',
//   name: '',
//   subtitle:
//     '',
//   description: `
//  `,
//   coverImg: '',
//   imgs: [
//     {
//       heading: '',
//       url: '',
//       url_dark_mode:
//         '',
//       summary: `

//         `,
//     },
//   ],
//   links: {
//     default: {
//       github:
//         '',
//       demo: '',
//     },
//     extra: [
//       {
//         html: '<a href="https://api.shoptree.ca/api/swagger" target="_new"><img style="border-radius: 32px;" src="https://img.shields.io/badge/OpenAPI%20Swagger-E70C1A.svg?style=for-the-badge&amp;logo=NestJS&amp;logoColor=white" alt="OpenAPI Swagger"></a>&nbsp;',
//       },
//     ],
//   },
//   tools: [
//     'NestJS',
//     'VueJS',
//   ],
// },
