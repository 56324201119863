<div [ngClass]="{'dark-theme': isDarkTheme | async}">
  <div class="mat-app-background">
    <div class="socialbar">
      <a target="_blank"
        class="github"
        href="https://github.com/NickDeLu">
        <i class="fa fa-square fa-stack-1x outside"></i>
        <i class="fa fa-github-square fa-stack-1x inside"></i>
      </a>
      <a target="_blank"
        class="linkedin"
        href="https://www.linkedin.com/in/nickdelu/">
        <i class="fa fa-square fa-stack-1x outside"></i>
        <i class="fa fa-linkedin-square fa-stack-1x inside"></i>
      </a>

      <a target="_blank" class="devpost" href="https://devpost.com/NickDeLu?ref_content=user-portfolio&ref_feature=portfolio&ref_medium=global-nav">
        <i class="fa fa-square fa-stack-1x outside" style="z-index: -1;margin-top: 10px;"></i>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 280.3 242" enable-background="new 0 0 280.3 242" xml:space="preserve">
            <g id="XMLID_1_">
                <path id="XMLID_6_" d="M133.7,76H118v90h14.7c30.9,0,45.1-18.1,45.1-45C177.8,90.9,164.9,76,133.7,76z"/>
                <path id="XMLID_9_" d="M210.2,0H70.1L0,121l70.1,121h140.1l70.1-121L210.2,0z M132.7,195H89V47h45.8c42.1,0,73.3,20.1,73.3,74   C208.1,172.8,170.6,195,132.7,195z"/>
            </g>
            </svg>
      </a>
    </div>
    <app-navbar [goBackPage]="router.url == '/' ? 'false' : 'true'"></app-navbar>



      <div class="app-wrapper">
        <div class="app-container">
          <ng-container *ngIf="router.url == '/resume'">
            <app-resume-page></app-resume-page>
          </ng-container>

          <ng-container *ngIf="router.url.includes('/projects')">
            <app-project-page></app-project-page>
          </ng-container>

          <ng-container>
        <div [ngClass]="{'invisible': router.url != '/'}">
  <!-- 
          <div style="position: relative;">
            <div class="blobs-container" id="route">
              <app-blobs-svg></app-blobs-svg>
            </div>
          </div> -->
        
            <section class="landing">
              <!-- <div class="spacer first"></div> -->
              <!-- <div id="particles-js"></div> -->
            
              <landing></landing>
            </section>



            <section class="about mt-1 d-flex justify-content-center">
              <div aria-hidden="true"class="gradient-line"></div>

              <div class="container d-flex flex-column justify-content-center align-items-center" style="padding: 80px 0px;     position: relative;">
                <h2 class="animate-text" style="text-align: center;">Leveraging Cutting-Edge Technologies</h2>
                <p class="animate-text">Driving success with modern tech stacks.</p>
                <br><br>
                <div class="skill-icons row justify-content-center fade-container" style="max-width: 1000px;">
                    <div class="col-lg-custom col-md-6 col-6 d-flex align-items-center mb-5 mt-1  justify-content-center">
                        <span class="ml-2 d-flex align-items-center">
                            <div class="icon angular"></div>&nbsp;&nbsp;Angular
                        </span>
                    </div>
                    <div class="col-lg-custom col-md-6 col-6 d-flex align-items-center mb-5 mt-1  justify-content-center">
                        <span class="ml-2 d-flex align-items-center">
                            <div class="icon node"></div>&nbsp;&nbsp;Node.js
                        </span>
                    </div>
                    <div class="col-lg-custom col-md-6 col-6 d-flex align-items-center mb-5 mt-1  justify-content-center">
                        <span class="ml-2 d-flex align-items-center">
                            <div class="icon mysql"></div>&nbsp;&nbsp;MySQL
                        </span>
                    </div>
                    <div class="col-lg-custom col-md-6 col-6 d-flex align-items-center mb-5 mt-1  justify-content-center">
                        <span class="ml-2 d-flex align-items-center">
                            <div class="icon react"></div>&nbsp;&nbsp;React
                        </span>
                    </div>
                    <div class="col-lg-custom col-md-6 col-6 d-flex align-items-center mb-5 mt-1  justify-content-center">
                      <span class="ml-2 d-flex align-items-center">
                          <div class="icon ts"></div>&nbsp;&nbsp;TypeScript
                      </span>
                    </div>
                    <div class="col-lg-custom col-md-6 col-6 d-flex align-items-center mb-5 mt-1  justify-content-center">
                        <span class="ml-2 d-flex align-items-center">
                            <div class="icon django"></div>&nbsp;&nbsp;Django
                        </span>
                    </div>
                    <div class="col-lg-custom col-md-6 col-6 d-flex align-items-center mb-5 mt-1  justify-content-center">
                        <span class="ml-2 d-flex align-items-center">
                            <div class="icon nestjs"></div>&nbsp;&nbsp;NestJS
                        </span>
                    </div>
                    <div class="col-lg-custom col-md-6 col-6 d-flex align-items-center mb-5 mt-1  justify-content-center">
                      <span class="ml-2 d-flex align-items-center">
                          <div class="icon python"></div>&nbsp;&nbsp;Python
                      </span>
                    </div>
                    <div class="col-lg-custom col-md-6 col-6 d-flex align-items-center mb-5 mt-1  justify-content-center">
                        <span class="ml-2 d-flex align-items-center">
                            <div class="icon docker"></div>&nbsp;&nbsp;Docker
                        </span>
                    </div>
                    <div class="col-lg-custom col-md-6 col-6 d-flex align-items-center mb-5 mt-1  justify-content-center">
                      <span class="ml-2 d-flex align-items-center">
                          <div class="icon kubernetes"></div>&nbsp;&nbsp;Kubernetes
                      </span>
                    </div>
                </div>
                <br>
                <div class="animate-text">And more...</div>
                <br>
                <app-scroll-svg></app-scroll-svg>
            </div>
            
            </section>


            <div style=" width: 100%; position: relative;  height: 100px;">
              <div aria-hidden="true"class="gradient-line"></div>
            </div>
            <div style="position: relative; height:100px;">
              <div class="wave" style="bottom:-40px;  position: absolute;">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M0,70 C100,100 230,90 340,75 C460,60 600,32 750,45 C930,60 1080,90 1200,35 V120 H0 Z" class="shape-fill"></path>
                </svg>
              </div>
            </div>
            <div class="spacer" style="position: relative;"></div>


            <section class="projects">
              <div aria-hidden="true"class="gradient-line" style="margin-top: 150px;"></div>

              <div class="container d-flex flex-column justify-content-center align-items-center" style="margin-top: 250px;">
                <h2 class="animate-text" style="text-align: center;">Innovative Solutions Through Software and Design</h2>
                <p class="animate-text" style="text-align: center;">From concept to deployment, these are the projects I've built.</p>
                <project-section></project-section>
                <div style=" width: 100%; position: relative; height: 50px; margin-top: 50px;">
                  <div aria-hidden="true"class="gradient-line"></div>
                </div>
              </div>
            </section>


        
            <div class="spacer-thin" style="position: relative;"></div>


            <section class="software-design position-relative" >
              <div class="phone">
                <div class="position-absolute p-4 d-flex text-align-center flex-column align-items-center justify-content-center w-100 radial-gradient" style="height: 100vh;">
                  <h2 class="animate-text" style="text-align: center; ">Responsive Design</h2>
                  <p class="animate-text" style="text-align: center; ">Creating PWAs designed for all view-ports.</p>
                </div>
                <div class="position-absolute p-4 d-flex text-align-center flex-column align-items-center justify-content-center w-100 radial-gradient" style="height: 100vh;">
                </div>
                <phone3d style="max-width:100vw; position: relative;"></phone3d>
              </div>

              <div style="margin-top: -10vh;">
                <div class="position-realtive p-4 d-flex text-align-center flex-column align-items-center justify-content-center w-100" style="">
                  <div style=" width: 100%; position: relative;  height: 100px;">
                    <div aria-hidden="true"class="gradient-line"></div>
                  </div>
        
                  <h2 class="animate-text" style="text-align: center; ">Security First Approach</h2>
                  <p class="animate-text" style="text-align: center; ">Incorporating Best Practices for Secure Development.</p>
                  <security-section class="mt-5"></security-section>

                  <div style=" width: 100%; position: relative;  height: 100px; margin-top: 100px">
                    <div aria-hidden="true"class="gradient-line"></div>
                  </div>
        
                </div>
              </div>

              <div>
                <div class="cicd-container position-realtive p-4 d-flex text-align-center flex-column align-items-center justify-content-center w-100" style="">
                  <h2 class="animate-text" style="text-align: center; ">Continuous Integration and Deployment</h2>
                  <p class="animate-text" style="text-align: center; ">Automating Delivery for Reliable Updates.</p>
                  <cicd style="margin-top: 50px;margin-bottom: 450px;"></cicd>
                </div>
              </div>
            </section>


            <div id="designSectionWave" class="wave position-relative" style="bottom:-20px; width:100%; ">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
              </svg>
            </div>
            <div class="spacer"></div>


            <section class="projects2" style="overflow: visible; height:calc(100vh - 500px)">
              <laptop3d style="z-index: 3;position: relative;"></laptop3d>
            </section>


            <div class="spacer"></div>


            <section class="experience" style="padding-top: 150px;">
              <div aria-hidden="true"class="gradient-line" style="margin-top:150px;"></div>
              <div class="container d-flex flex-column justify-content-center align-items-center" style="padding: 80px 0px;     position: relative;">
                <h2 class="animate-text" style="text-align: center;">Professional Career Journey</h2>
                <p class="animate-text">A glimpse into my career evolution and growth.</p>
              <experience-timeline></experience-timeline>
              </div>
            </section>


            <div style=" width: 100%; position: relative;  height: 100px;">
              <div aria-hidden="true"class="gradient-line"></div>
            </div>
            <div class="wave" style="bottom: -100px; position: relative;margin-top: -200px;">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,60 C100,80 250,90 370,70 C480,50 600,30 740,50 C850,70 950,90 1200,40 V120 H0 Z" class="shape-fill"></path>
              </svg>
            </div>
            <div class="spacer"></div>


            <section class="contact">
              <div aria-hidden="true"class="gradient-line" style="margin-top:150px;"></div>
              <div class="container d-flex flex-column justify-content-center align-items-center" style="height:100vh;margin: 100px auto -40px auto; position: relative; ">
                <h2 class="animate-text" style="text-align: center;">Get in Touch to Start a Conversation</h2>
                <p class="animate-text">An opportunity for collaboration awaits.</p>
                <app-contact style="margin-top: 25px;"></app-contact>
              </div>
            </section>


            <div style=" width: 100%; position: relative;  height: 100px; margin-bottom: -100px;">
              <div aria-hidden="true"class="gradient-line"></div>
            </div>
            <div class="spacer-thin" style="transform: scaleY(-1) scaleX(-1);"></div>
            
          </div>
          </ng-container>

          <app-footer style="z-index: 2;position: relative;"></app-footer>
 
        </div>
      </div>
  

  </div>
</div>